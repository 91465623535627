import {
  SET_COUNTRY,
  SET_DOCUMENT_FILE,
  SET_REPORT_TITLE,
  SET_LANGAUGE,
  SET_LOCATION,
  SET_LOCATION_OPTIONS,
  SET_SEARCH_ENGINE,
  SET_TARGET_DOMAIN,
  SET_IGNORE_SPECIAL_CHARACTERS,
  SET_IS_START_JOB_BUTTON_CLICKED,
  SET_GROUPING_METHOD,
  SET_MAIN_KEYWORD_GROUPING_ACCURACY,
  SET_VARIANT_KEYWORD_GROUPING_ACCURACY,
  SET_POSITION_THRESHOLD,
  SET_DEFAULT_JOB_VALUES,
  SET_VARIANT_POSITION_THRESHOLD,
  SET_NO_OF_CLUSTERS,
  SET_SENDING_FILE_PROGRESS,
} from '../action-types/job-fields';

export const setReportTitle = (title) => ({
  type: SET_REPORT_TITLE,
  payload: title,
});

export const setTargetDomain = (domain) => ({
  type: SET_TARGET_DOMAIN,
  payload: domain,
});

export const setSearchEngine = (engine) => ({
  type: SET_SEARCH_ENGINE,
  payload: engine,
});
export const setCountry = (country) => ({
  type: SET_COUNTRY,
  payload: country,
});
export const setLocation = (location) => ({
  type: SET_LOCATION,
  payload: location,
});
export const setLocationOptions = (options) => ({
  type: SET_LOCATION_OPTIONS,
  payload: options,
});
export const setLanguage = (language) => ({
  type: SET_LANGAUGE,
  payload: language,
});
export const setDocumentFile = (file) => ({
  type: SET_DOCUMENT_FILE,
  payload: file,
});
export const setIgnoreSpecialCharacters = (flag) => ({
  type: SET_IGNORE_SPECIAL_CHARACTERS,
  payload: flag,
});
export const setisStartJobButtonClicked = (flag) => ({
  type: SET_IS_START_JOB_BUTTON_CLICKED,
  payload: flag,
});
export const setGroupingMethod = (method) => ({
  type: SET_GROUPING_METHOD,
  payload: method,
});
export const setMainKeywordGroupingAccuracy = (accuracy) => ({
  type: SET_MAIN_KEYWORD_GROUPING_ACCURACY,
  payload: accuracy,
});
export const setVariantKeywordGroupingAccuracy = (accuracy) => ({
  type: SET_VARIANT_KEYWORD_GROUPING_ACCURACY,
  payload: accuracy,
});
export const setPositionThreshold = (threshold) => ({
  type: SET_POSITION_THRESHOLD,
  payload: threshold,
});
export const setVariantPositionThreshold = (threshold) => ({
  type: SET_VARIANT_POSITION_THRESHOLD,
  payload: threshold,
});
export const setDefaultJobFields = () => ({
  type: SET_DEFAULT_JOB_VALUES,
});

export const setNoOfClusters = (noOfClusters) => ({
  type: SET_NO_OF_CLUSTERS,
  payload: noOfClusters,
});
export const setSendingFileProgress = (progress) => ({
  type: SET_SENDING_FILE_PROGRESS,
  payload: progress,
});
