import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  hasUserMasterPassword,
  setupMasterPassword,
} from '../../../../utils/api';
import Error from '../../../Common/Error/Error';
import InputText from '../../../Common/InputText/InputText';
import './styles.scss';
const MasterPasswordCard = () => {
  const [isMasterPassword, setIsMasterPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [didSaveBtnClick, setDidSaveBtnClick] = useState(false);
  const checkMasterPassword = () => {
    hasUserMasterPassword()
      .then(({ data }) => {
        setIsMasterPassword(data);
      })
      .catch(() => {
        setIsMasterPassword(false);
      });
  };
  useEffect(() => {
    checkMasterPassword();
  }, []);
  const savePassword = () => {
    setDidSaveBtnClick(true);
    if (
      password.trim()?.length >= 6 &&
      password.trim() === confirmPassword.trim()
    ) {
      setupMasterPassword({ password: password.trim() })
        .then(({ data: { message } }) => {
          checkMasterPassword();
          setIsCreating(false);
          setIsUpdating('');
          setDidSaveBtnClick(false);
          setPassword('');
          setConfirmPassword('');
          toast.success(message);
        })
        .catch((error) => {
          toast.error(error?.data?.message);
        });
    }
  };
  const onCancel = () => {
    setIsCreating(false);
    setIsUpdating(false);
    setDidSaveBtnClick(false);
    setPassword('');
    setConfirmPassword('');
  };
  return (
    <div className='master-password-card inputs-form'>
      <div>master password</div>
      <>
        {isCreating || isUpdating ? (
          <div>
            <InputText
              type='password'
              label={isMasterPassword ? 'NEW PASSWORD' : 'PASSWORD'}
              state={password}
              setState={(e) => setPassword(e?.target?.value)}
              stylingClass='login-input-label input-select-label'
              onEnterPress={savePassword}
            />
            <Error
              message='Password should be at least 6 characters long'
              isVisible={
                (didSaveBtnClick || password.trim()) &&
                password.trim()?.length < 6
              }
            />
            <InputText
              type='password'
              label={
                isMasterPassword ? 'NEW CONFIRM PASSWORD' : 'CONFIRM PASSWORD'
              }
              state={confirmPassword}
              setState={(e) => setConfirmPassword(e?.target?.value)}
              stylingClass='login-input-label input-select-label'
              onEnterPress={savePassword}
            />
            <Error
              message='Passwords does not match'
              isVisible={
                didSaveBtnClick &&
                password.trim() &&
                password.trim() !== confirmPassword.trim()
              }
            />
            <div className='master-password-buttons'>
              <button className='custom-button' onClick={savePassword}>
                save
              </button>
              <button
                className='custom-button cancel-button'
                onClick={onCancel}
              >
                cancel
              </button>
            </div>
          </div>
        ) : (
          <button
            className='custom-button create-password-btn'
            onClick={() => {
              if (isMasterPassword) {
                setIsUpdating(true);
              } else {
                setIsCreating(true);
              }
            }}
          >
            {isMasterPassword ? 'update' : 'create'}
          </button>
        )}
      </>
    </div>
  );
};

export default MasterPasswordCard;
