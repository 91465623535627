export const SET_REPORT_TITLE = 'SET_REPORT_TITLE';
export const SET_NO_OF_CLUSTERS = 'SET_NO_OF_CLUSTERS';
export const SET_TARGET_DOMAIN = 'SET_TARGET_DOMAIN';
export const SET_SEARCH_ENGINE = 'SET_SEARCH_ENGINE';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATION_OPTIONS = 'SET_LOCATION_OPTIONS';
export const SET_LANGAUGE = 'SET_LANGAUGE';
export const SET_DOCUMENT_FILE = 'SET_DOCUMENT_FILE';
export const SET_IGNORE_SPECIAL_CHARACTERS = 'SET_IGNORE_SPECIAL_CHARACTERS';
export const SET_IS_START_JOB_BUTTON_CLICKED =
  'SET_IS_START_JOB_BUTTON_CLICKED';
export const SET_GROUPING_METHOD = 'SET_GROUPING_METHOD';
export const SET_MAIN_KEYWORD_GROUPING_ACCURACY =
  'SET_MAIN_KEYWORD_GROUPING_ACCURACY';
export const SET_VARIANT_KEYWORD_GROUPING_ACCURACY =
  'SET_VARIANT_KEYWORD_GROUPING_ACCURACY';
export const SET_POSITION_THRESHOLD = 'SET_POSITION_THRESHOLD';
export const SET_VARIANT_POSITION_THRESHOLD = 'SET_VARIANT_POSITION_THRESHOLD';
export const SET_DEFAULT_JOB_VALUES = 'SET_DEFAULT_JOB_VALUES';
export const SET_SENDING_FILE_PROGRESS = 'SET_SENDING_FILE_PROGRESS';
