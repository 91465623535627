import {
  SET_IS_ADMIN,
  SET_IS_USER_LOGGED_IN,
  SET_PENDING_INVITES,
} from '../action-types/auth';

export const setIsUserLoggedIn = (flag) => ({
  type: SET_IS_USER_LOGGED_IN,
  payload: flag,
});
export const setPendingInvites = (invites) => ({
  type: SET_PENDING_INVITES,
  payload: invites,
});
export const setIsAdmin = (flag) => ({
  type: SET_IS_ADMIN,
  payload: flag,
});
