import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Sidebar from './components/Common/Sidebar/Sidebar';
import FetcherGrouper from './components/Jobs/FetcherGrouper/FetcherGrouper';
import KeywordGrouper from './components/Jobs/KeywordGrouper/KeywordGrouper';
import SerpFetcher from './components/Jobs/SerpFetcher/SerpFetcher';
import Details from './components/Common/Details/Details';
import { PUBLIC_URL } from './constants/environment-variables';
import JobLogs from './components/Common/Details/JobLogs';
import Login from './components/Auth/Login';
import {
  ACTIVE_JOBS_PATH,
  ARCHIVED_JOBS_PATH,
  FETCHER_GROUPER_PATH,
  // KEYWORD_HUB_CREATOR,
  FETCHER_PATH,
  GROUPER_PATH,
  ADMIN_CONSOLE_PATH,
} from './constants/paths';
import { useSelector } from 'react-redux';
import JobSelector from './components/Jobs/JobSelector';
// import { HubCreator } from './components/Jobs/HubCreator/HubCreator';
import Users from './components/Console/Users/Users';
import PendingInvites from './components/Console/PendingInvites/PendingInvites';
import Verification from './components/Auth/Verification';
import Settings from './components/Console/Settings/Settings';
import { getAppUser } from './utils/helpers';
const Router = () => {
  const isUserLoggedIn = useSelector((state) => state?.auth?.isUserLoggedIn);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  useEffect(() => {
    if (window.innerWidth <= 850) {
      setIsSideBarOpen(false);
    }
  }, []);

  return (
    <Switch>
      {isUserLoggedIn ? (
        <div className='app-content'>
          <Sidebar
            isSideBarOpen={isSideBarOpen}
            setIsSideBarOpen={setIsSideBarOpen}
          />

          <div
            className={`section-container ${
              isSideBarOpen
                ? 'opened-side-bar-screen'
                : 'closed-side-bar-screen'
            }`}
          >
            <Switch>
              <Route
                exact
                path={`${PUBLIC_URL}${FETCHER_PATH}`}
                render={() => <SerpFetcher />}
              />
              <Route
                exact
                path={`${PUBLIC_URL}${GROUPER_PATH}`}
                render={() => <KeywordGrouper />}
              />
              <Route
                exact
                path={`${PUBLIC_URL}${FETCHER_GROUPER_PATH}`}
                render={() => <FetcherGrouper />}
              />
              {/* <Route
                exact
                path={`${PUBLIC_URL}${KEYWORD_HUB_CREATOR}`}
                render={() => <HubCreator />}
              /> */}
              <Route
                exact
                path={`${PUBLIC_URL}${ACTIVE_JOBS_PATH}`}
                render={() => <JobSelector jobType='active' />}
              />
              <Route
                exact
                path={`${PUBLIC_URL}${ARCHIVED_JOBS_PATH}`}
                render={() => <JobSelector jobType='archived' />}
              />
              <Route
                exact
                path={`${PUBLIC_URL}/jobs/:jobState/:jobType/:jobId/details`}
                render={() => <Details />}
              />
              <Route
                exact
                path={`${PUBLIC_URL}/jobs/:jobState/:jobType/:jobId/details/logs`}
                render={() => <JobLogs />}
              />
              {getAppUser()?.role === 'ADMIN' && (
                <>
                  <Route
                    exact
                    path={`${PUBLIC_URL}${ADMIN_CONSOLE_PATH}`}
                    render={() => <Users />}
                  />
                  <Route
                    path={`${PUBLIC_URL}${ADMIN_CONSOLE_PATH}/users`}
                    render={() => <Users />}
                  />
                  <Route
                    path={`${PUBLIC_URL}${ADMIN_CONSOLE_PATH}/pending-invites`}
                    render={() => <PendingInvites />}
                  />
                  <Route
                    path={`${PUBLIC_URL}${ADMIN_CONSOLE_PATH}/settings`}
                    render={() => <Settings />}
                  />
                  <Route
                    exact
                    path={`${PUBLIC_URL}/jobs/:userId/:jobState/:jobType/:jobId/details`}
                    render={() => <Details />}
                  />
                  <Route
                    exact
                    path={`${PUBLIC_URL}/jobs/:userId/:jobState/:jobType/:jobId/details/logs`}
                    render={() => <JobLogs />}
                  />
                  <Route
                    exact
                    path={`${PUBLIC_URL}/jobs/:userId/active`}
                    render={() => <JobSelector jobType='active' />}
                  />
                </>
              )}
              {getAppUser()?.role !== 'ADMIN' && (
                <Redirect from='/' to={`${PUBLIC_URL}${ACTIVE_JOBS_PATH}`} />
              )}
            </Switch>
          </div>
        </div>
      ) : (
        <>
          <Route
            path={`${PUBLIC_URL}/signup`}
            render={() => <Verification />}
          />
          <Route exact path={`${PUBLIC_URL}/`} render={() => <Login />} />
          <Route path={`${PUBLIC_URL}/login`} render={() => <Login />} />
        </>
      )}
    </Switch>
  );
};

export default Router;
