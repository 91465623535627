import { applyMiddleware, compose, createStore } from 'redux';
import { rootReucer } from '../reducers';
import thunk from 'redux-thunk';
export const store = createStore(
  rootReucer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (args) => args
  )
);
