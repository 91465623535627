export const validImageFormats = [
  'png',
  'jpg',
  'jpeg',
  'apng',
  'avif',
  'webp',
  'jfif',
  'pjpeg',
  'pjp',
];
