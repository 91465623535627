import React, { useEffect, useState } from 'react';
import { validate } from 'email-validator';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { getPendingInvitations, inviteUser } from '../../../utils/api';
import './InvitePopover.scss';
import { toast } from 'react-toastify';
import { setPendingInvites } from '../../../redux/actions/auth';
import { useDispatch } from 'react-redux';
const InvitePopover = ({ children }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    setEmail('');
  }, []);
  const sendInvite = () => {
    if (validate(email)) {
      inviteUser({ email })
        .then(() => {
          toast.success('User invited successfully.');
          getPendingInvitations().then(({ data }) => {
            dispatch(setPendingInvites(data));
          });
          setEmail('');
          document.body.click();
        })
        .catch((error) => {
          toast.error(
            error?.response.data?.message ?? 'User can not be invited.'
          );
        });
    }
  };
  const onEnterPress = (e) => {
    if (e?.key === 'Enter') {
      sendInvite();
    }
  };
  return (
    <OverlayTrigger
      rootClose
      trigger='click'
      placement='bottom'
      overlay={
        <Popover className='invite-popover' placement='bottom'>
          <div>
            <div className='invite-popover-head'>Invite to Keyword Grouper</div>
            <input
              className='invite-popover-input'
              value={email}
              onChange={(e) => {
                setEmail(e?.target?.value);
                setIsDisabled(!validate(e?.target?.value));
              }}
              onKeyPress={onEnterPress}
            />
            <center>
              <div
                className={`invite-button ${
                  isDisabled
                    ? 'disabled-invite-button'
                    : 'invite-popover-button'
                }`}
                onClick={sendInvite}
              >
                Send Invitation
              </div>
            </center>
          </div>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default InvitePopover;
