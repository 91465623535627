import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getJob, getJobLogs } from '../../../utils/api';
import Loading from '../Loading/Loading';
import DarkModeToggle from 'react-dark-mode-toggle';
import { JOB_LOGS_BY_NODE, NODE_JOB_STATUS } from '../../../constants/socket';
import useCustomSocket from '../../../hooks/useCustomSocket';
import TopLoadingBar from '../TopLoadingBar/TopLoadingBar';
import PulsatingDot from '../PulsatingDot/PulsatingDot';
import { useDispatch } from 'react-redux';
import { setJobType } from '../../../redux/actions/job-details';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
import { setSelectedAdminScreen } from '../../../redux/actions/sidebar';

const JobLogs = () => {
  const dispatch = useDispatch();
  const [didLogsLoad, setDidLogsLoad] = useState(false);
  const [jobLogs, setJobLogs] = useState([]);
  const [jobTitle, setJobTitle] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [jobStatus, setJobStatus] = useState('');

  const history = useHistory();
  const params = useParams();
  const id = params?.jobId;
  const type = params?.jobType;
  const state = params?.jobState;
  const userId = params?.userId;
  const appendLog = useCallback(
    ({ jobId, log, timestamp }) => {
      if (jobId === id) {
        if (!didLogsLoad) setDidLogsLoad(true);
        setJobLogs((prevLogs) => {
          const existingLogs = prevLogs.map((entry) => entry.log);
          if (!existingLogs.includes(log)) {
            const newLogEntry = { log, timestamp };
            const updatedLogs = [...prevLogs, newLogEntry];
            // Sort the logs by timestamp
            return updatedLogs.sort((a, b) => a.timestamp - b.timestamp);
          } else {
            return prevLogs;
          }
        });
      }
    },
    [id, didLogsLoad]
  );

  const socketJobLogs = (message) => {
    if (message.event === JOB_LOGS_BY_NODE) {
      appendLog(message);
    }
  };
  useCustomSocket(socketJobLogs);

  useEffect(() => {
    getJobLogs(id).then(({ data: { logs, report_title, status } }) => {
      setJobTitle(report_title);

      // Sort logs by timestamp before setting them
      const sortedLogs = logs.sort((a, b) => a.timestamp - b.timestamp);
      setJobLogs(sortedLogs);
      setDidLogsLoad(logs?.length > 0 || status !== 'running');
    });
  }, [id, type]);

  useEffect(() => {
    getJob(id).then(({ data }) => {
      setJobStatus(data?.status);
    });
  }, [id, type]);

  const updateStatus = useCallback(
    ({ jobId, status }) => {
      if (id === jobId) {
        setJobStatus(status);
      }
    },
    [id]
  );

  const socketJobStatus = (message) => {
    if (message.event === NODE_JOB_STATUS) {
      updateStatus(message);
    }
  };
  useCustomSocket(socketJobStatus);

  useEffect(() => {
    if (userId) {
      dispatch(setJobType(`${ADMIN_CONSOLE_PATH}/users`));
      dispatch(setSelectedAdminScreen('users'));
    } else {
      dispatch(setJobType(`/jobs/${state}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className='breadcrumb-container'>
        <span
          onClick={() => history.go(-2)}
          className='cursor-pointer breadcrumb-link'
        >
          {userId
            ? 'All Jobs'
            : state === 'active'
            ? 'Active Jobs'
            : 'Archived Jobs'}
        </span>
        <span className='breadcrumb-separator'>{'>'}</span>
        <span
          onClick={() => history.goBack()}
          className='cursor-pointer breadcrumb-link'
        >
          {jobTitle}
        </span>
        <span className='breadcrumb-separator'>{'>'}</span>Logs
      </div>
      <div className='job-logs'>
        <div className='logs-header'>
          <div className='logs-header-status-container'>
            <span>Status: </span>
            <PulsatingDot status={jobStatus} />
            <span>{jobStatus ?? 'Loading'}</span>
          </div>
          <div className='switch-button-container'>
            <span className='mode-text'>
              {!isDarkMode ? 'Light' : 'Dark'} Mode
            </span>
            <DarkModeToggle
              onChange={setIsDarkMode}
              checked={isDarkMode}
              size={50}
              speed={5}
            />
          </div>
        </div>
        <div
          className={`logs-container ${
            isDarkMode ? 'dark-mode' : 'light-mode'
          }`}
        >
          <TopLoadingBar id={id} status={jobStatus} />
          {didLogsLoad ? (
            <div>
              {jobLogs?.map((jobLog, index) => {
                return <div key={index}>{jobLog.log}</div>;
              })}
            </div>
          ) : (
            <div className='logs-loader-container'>
              <Loading whichLoader='logs' />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JobLogs;
