import { Tooltip } from '@material-ui/core';
import React from 'react';
import { OverlayTrigger, Popover, Alert } from 'react-bootstrap';
import './CustomPopover.scss';
const CustomPopover = ({ children, confirmButtonFunction, data }) => {
  const popOverStateHandler = (e) => {
    e.preventDefault();
    document.body.click();
  };
  const confirmButtonHandler = (e) => {
    e.preventDefault();
    document.body.click();
    confirmButtonFunction();
  };
  return (
    <OverlayTrigger
      rootClose
      trigger='click'
      placement='left'
      overlay={
        <Popover className='delete-alert-popover' placement='bottom'>
          <Alert variant='custom-warning'>
            <span className='delete-alert-message'>
              Deleting the job(s) will result in permanent deletion of all the
              report files also, are you sure you want to delete?
            </span>
          </Alert>

          <div className='delete-schedule-popover-buttons-container'>
            <div onClick={confirmButtonHandler} className='confirm-btn'>
              YES
            </div>
            <div onClick={popOverStateHandler} className='cancel-btn'>
              NO
            </div>
          </div>
        </Popover>
      }
    >
      <Tooltip title='Delete'>{children}</Tooltip>
    </OverlayTrigger>
  );
};

export default CustomPopover;
