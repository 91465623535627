import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import inviteIcon from '../../../assets/img/invite-icon.svg';
import Heading from '../../Common/Heading/Heading';
import archiveIcon from '../../../assets/img/archive-jobs-icon.svg';
import archiveIconGrey from '../../../assets/img/archive-icon-grey.svg';
import moment from 'moment';
import loadMoreIconGrey from '../../../assets/img//load-more-icon-grey.svg';
import EmptyTable from '../../Common/EmptyTable/EmptyTable';
import Loading from '../../Common/Loading/Loading';
import {
  changeUsersActiveStatus,
  getUsers,
  updateAPICredits,
} from '../../../utils/api';
import { setAllUsers } from '../../../redux/actions/users';
import { setDidDataLoad } from '../../../redux/actions/jobs';
import { toast } from 'react-toastify';
import ArchivedUsersModal from '../../Common/ArchivedUsersModal/ArchivedUsersModal';
import InvitePopover from '../../Common/InvitePopover/InvitePopover';
import { useHistory } from 'react-router';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
import { setJobType } from '../../../redux/actions/job-details';
import { Tooltip } from '@material-ui/core';
import { USER_API_CREDITS } from '../../../constants/socket';
import { useSortUsers } from '../../../hooks/useSortUsers';
import useCustomSocket from '../../../hooks/useCustomSocket';
const Users = () => {
  const users = useSelector((state) => state?.users?.allUsers);

  const [filteredUsers, setFilteredUsers] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [toggledCreationTimes, setToggledCreationTimes] = useState([]);
  const [shownResults, setShownResults] = useState(10);
  const [showArchivedUsers, setShowArchivedUsers] = useState(false);
  const [editingCredit, setEditingCredit] = useState(null);
  const [apiCredits, setApiCredits] = useState(null);
  const [enterPressed, setEnterPressed] = useState(false);

  const didDataLoad = useSelector((state) => state?.jobs?.didDataLoad);
  const jobType = useSelector((state) => state?.jobDetails?.jobType);

  const dispatch = useDispatch();

  const {
    lastUsedSortFilter,
    loginSortFilter,
    sortByLastUsed,
    sortByLastLogin,
  } = useSortUsers({ users, setFilteredUsers });

  const { push } = useHistory();
  const getAllUsers = useCallback(() => {
    getUsers().then(({ data }) => {
      const usersList = data?.map((user) => ({
        ...user?.user,
        latestJob: user?.latestJob,
      }));
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
      dispatch(setDidDataLoad(true));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const archivedSelectedUsers = () => {
    if (selectedUsers?.length > 0) {
      const usersToBeArchived = users?.filter(({ _id }) =>
        selectedUsers?.includes(_id)
      );
      changeUsersActiveStatus({ users: usersToBeArchived, action: 'archive' })
        .then(({ data }) => {
          setSelectedUsers([]);
          getAllUsers();
          toast.success(data?.message);
        })
        .catch(() => toast.error('Can not archive users.'));
    }
  };
  useEffect(() => {
    push(`${ADMIN_CONSOLE_PATH}/users`);
    dispatch(setJobType('/admin/console/users'));
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllUsers]);
  useEffect(() => {
    setFilteredUsers(users);
    setSelectedUsers([]);
    setSearchValue('');
    setShownResults(10);
  }, [users]);
  const toggleSelection = (jobId) => {
    if (selectedUsers?.includes(jobId)) {
      const updatedJobs = selectedUsers?.filter((id) => id !== jobId);
      setSelectedUsers(updatedJobs);
    } else {
      const updatedJobs = [...selectedUsers, jobId];
      setSelectedUsers(updatedJobs);
    }
  };
  const toggleAllSelection = () => {
    const allJobs = users?.map(({ _id }) => _id);
    if (selectedUsers?.length < allJobs?.length) {
      setSelectedUsers(allJobs);
    } else {
      setSelectedUsers([]);
    }
  };
  const toggleCreationTime = (id) => {
    if (toggledCreationTimes?.includes(id)) {
      setToggledCreationTimes(
        toggledCreationTimes?.filter((jobId) => jobId !== id)
      );
    } else {
      setToggledCreationTimes([...toggledCreationTimes, id]);
    }
  };

  const toggleArchivedUsers = () => {
    setShowArchivedUsers(!showArchivedUsers);
  };

  const searchUsers = (value) => {
    if (!value) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users?.filter(({ name }) =>
          name?.toLowerCase()?.includes(value?.toLowerCase())
        )
      );
    }
  };
  const loadMore = () => setShownResults(shownResults + 10);
  const viewUserDetails = (userId) => {
    dispatch(setJobType(ADMIN_CONSOLE_PATH));
    push(`/jobs/${userId}/active`);
  };

  const updateCredits = ({ userId }) => {
    setEnterPressed(true);
    if (userId && apiCredits) {
      setEnterPressed(false);
      updateAPICredits({ userId, apiCredits })
        .then(({ data }) => {
          setEditingCredit(null);
          getAllUsers();
          toast.success(data?.message);
        })
        .catch(({ data }) => {
          toast.error(data?.message);
        });
    }
  };
  const onEnterPress = (e) => {
    if (e?.key === 'Enter') {
      updateCredits({ userId: editingCredit });
    }
  };

  const readAPICredits = useCallback(
    ({ userId, apiCredits }) => {
      const userToBeUpdated = users?.filter(({ _id }) => _id === userId)?.[0];
      if (userToBeUpdated) {
        const index = users?.indexOf(userToBeUpdated);
        let tempUsers = [...users];
        tempUsers[index] = { ...userToBeUpdated, api_credits: apiCredits };
        dispatch(setAllUsers(tempUsers));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users]
  );

  const socketUserCredits = (message) => {
    if (message.event === USER_API_CREDITS) {
      readAPICredits(message);
    }
  };
  useCustomSocket(socketUserCredits);

  return (
    <>
      <Heading text='USERS' />
      {didDataLoad ? (
        <div className='jobs-card'>
          <div className='jobs-card-header d-flex jc-sp ai-center'>
            <div className='d-flex'>
              <div
                className={`d-flex ai-center action-card ${
                  selectedUsers?.length === 0 ? 'disabled-action-card' : ''
                } cursor-pointer`}
                onClick={archivedSelectedUsers}
              >
                <img
                  src={
                    jobType === '/admin/console/users' &&
                    selectedUsers?.length === 0
                      ? archiveIconGrey
                      : jobType === '/admin/console/users' &&
                        selectedUsers?.length !== 0
                      ? archiveIcon
                      : ''
                  }
                  alt='archive'
                  className='action-bar-icon'
                />
                {jobType === 'archived' ? 'Unarchive' : 'Archive'} Selected
              </div>
              <InvitePopover>
                <div className='d-flex ai-center action-card invite-card cursor-pointer'>
                  <img
                    src={inviteIcon}
                    alt='archive'
                    className='action-bar-icon'
                  />
                  Invite
                </div>
              </InvitePopover>
            </div>
            <div className='d-flex ai-center'>
              <div
                className='action-card invite-card cursor-pointer view-archived-users right'
                onClick={toggleArchivedUsers}
              >
                View Archived Users
              </div>
              {showArchivedUsers && (
                <ArchivedUsersModal
                  show={showArchivedUsers}
                  handleClose={toggleArchivedUsers}
                />
              )}

              <div className='d-flex ai-center action-card right'>
                <div className='ui search searchbar-container'>
                  <div className='ui icon input'>
                    <input
                      className='prompt'
                      type='text'
                      placeholder='Search Users'
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e?.target?.value);
                        searchUsers(e?.target?.value);
                      }}
                    />
                    <i className='search icon' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {users?.filter(({ is_archived }) => !is_archived)?.length !== 0 ? (
            <>
              {filteredUsers?.length !== 0 ? (
                <>
                  <div className='jobs-card-table'>
                    <table
                      border={1}
                      cellPadding={20}
                      cellSpacing={10}
                      className='jobs-table user-table'
                    >
                      <thead>
                        <th>
                          <label className='custom-checkbox-container'>
                            <input
                              type='checkbox'
                              className='custom-checkbox'
                              onClick={toggleAllSelection}
                              disabled={
                                users?.filter(({ is_archived }) => !is_archived)
                                  ?.length === 0
                              }
                              checked={
                                users?.length > 0 &&
                                users?.length === selectedUsers?.length
                              }
                            />
                            <span className='custom-checkmark'></span>
                          </label>
                        </th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>API CREDITS</th>
                        <th>CREATED</th>
                        <th
                          className='cursor-pointer'
                          style={
                            lastUsedSortFilter === 'default'
                              ? {}
                              : { color: 'black' }
                          }
                          onClick={sortByLastUsed}
                        >
                          LAST USED{' '}
                          {lastUsedSortFilter === 'desc' ? (
                            <>&#8593;</>
                          ) : lastUsedSortFilter === 'asc' ? (
                            <>&#8595;</>
                          ) : null}
                        </th>
                        <th
                          className='cursor-pointer'
                          style={
                            loginSortFilter === 'default'
                              ? {}
                              : { color: 'black' }
                          }
                          onClick={sortByLastLogin}
                        >
                          LAST LOGIN
                          {loginSortFilter === 'desc' ? (
                            <>&#8593;</>
                          ) : loginSortFilter === 'asc' ? (
                            <>&#8595;</>
                          ) : null}
                        </th>

                        <th />
                      </thead>
                      <tbody>
                        {filteredUsers?.map(
                          (
                            {
                              username,
                              _id,
                              name,
                              api_credits,
                              createdAt,
                              is_archived,
                              latestJob,
                              last_login,
                            },
                            index
                            // eslint-disable-next-line array-callback-return
                          ) => {
                            if (index < shownResults && !is_archived) {
                              return (
                                <tr key={_id}>
                                  <td>
                                    <label className='custom-checkbox-container'>
                                      <input
                                        type='checkbox'
                                        className='custom-checkbox'
                                        onClick={() => toggleSelection(_id)}
                                        checked={selectedUsers?.includes(_id)}
                                      />
                                      <span className='custom-checkmark' />
                                    </label>
                                  </td>
                                  <td title={name}>{name}</td>
                                  <td title={username}>{username}</td>
                                  <td
                                    onDoubleClick={() => {
                                      setEditingCredit(_id);
                                      setApiCredits(api_credits);
                                    }}
                                  >
                                    {editingCredit === _id ? (
                                      <div className='edit-credits-input'>
                                        <input
                                          type='number'
                                          value={apiCredits}
                                          onChange={(e) =>
                                            setApiCredits(e?.target?.value)
                                          }
                                          onKeyPress={onEnterPress}
                                          className={
                                            enterPressed && !apiCredits
                                              ? 'api-credits-input-error'
                                              : ''
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <Tooltip
                                        title='Double click to edit'
                                        placement='right'
                                      >
                                        <span className='cursor-pointer'>
                                          {api_credits}
                                        </span>
                                      </Tooltip>
                                    )}
                                  </td>
                                  <td
                                    onClick={() => toggleCreationTime(_id)}
                                    className='created-at-td'
                                  >
                                    {toggledCreationTimes?.includes(_id)
                                      ? moment(createdAt).format(
                                          'MM/DD/YY - hh:mm a'
                                        )
                                      : moment(createdAt).fromNow()}
                                  </td>
                                  <td>
                                    {latestJob
                                      ? moment(latestJob).fromNow()
                                      : '-'}
                                  </td>
                                  <td>
                                    {last_login
                                      ? moment(last_login).fromNow()
                                      : '-'}
                                  </td>
                                  <td>
                                    <button
                                      className='custom-button download-btn'
                                      onClick={() => viewUserDetails(_id)}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='jobs-card-footer'>
                    {shownResults < filteredUsers?.length && (
                      <span className='load-more-btn' onClick={loadMore}>
                        <img src={loadMoreIconGrey} alt='load-more' />
                        Load more...
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <EmptyTable text='No match found' />
              )}
            </>
          ) : (
            <>
              <EmptyTable text={`No ${jobType.split('/').splice(-1)} found`} />
            </>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Users;
