import {
  SET_IS_ADMIN,
  SET_IS_USER_LOGGED_IN,
  SET_PENDING_INVITES,
} from '../action-types/auth';

const initialState = {
  isUserLoggedIn: false,
  pendingInvites: [],
  isAdmin: false,
};
export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_USER_LOGGED_IN: {
      return {
        ...state,
        isUserLoggedIn: payload,
      };
    }
    case SET_PENDING_INVITES: {
      return {
        ...state,
        pendingInvites: payload,
      };
    }
    case SET_IS_ADMIN: {
      return {
        ...state,
        isAdmin: payload,
      };
    }
    default:
      return state;
  }
};
