import React from 'react';
import './styles.scss';
const JobWaitingLoader = () => {
  return (
    <div className='job-waiting-loader-container'>
      <div className='job-waiting-loader' />
    </div>
  );
};

export default JobWaitingLoader;
