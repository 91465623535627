import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './InputSelect.scss';
const InputSelect = ({ label, options, state, setState }) => (
  <div className='input-select-container'>
    <label className='input-select-label'>{label}</label>
    <DropdownButton
      id='dropdown-item-button'
      className='input-select'
      title={state}
    >
      {options?.map((option) => (
        <Dropdown.Item
          className={state === option ? 'input-selected-item' : ''}
          key={option}
          value={option}
          onClick={setState.bind(this, option)}
        >
          {option}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </div>
);

export default InputSelect;
