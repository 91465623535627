import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch } from 'react-redux';
import {
  setIsUserAvatarLoading,
  setUserAvatar,
} from '../../../redux/actions/users';
import { updateUserAvatar } from '../../../utils/api';
import PicLoader from './PicLoader';

const ImageCropper = ({
  selectedImage,
  upImg,
  isCropping,
  handleClose,
  hasImageLoaded,
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 50,
    height: 50,
    x: 0,
    y: 0,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isCroppingDone, setIsCroppingDone] = useState(false);

  const dispatch = useDispatch();

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    const img = imgRef.current;
    const smallerParam = img?.width >= img?.height ? img?.width : img?.height;
    const croppedImgObject = {
      width: smallerParam / 2,
      height: smallerParam / 2,
      x: img?.width / 2 - smallerParam / 4,
      y: img?.height / 2 - smallerParam / 4,
      unit: 'px',
      aspect: 1,
    };
    setCrop(croppedImgObject);
    setCompletedCrop(croppedImgObject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    imgRef?.current?.height,
    imgRef?.current?.width,
    previewCanvasRef.current?.id,
  ]);
  useEffect(() => {
    const img = imgRef.current;
    const smallerParam = img?.width >= img?.height ? img?.width : img?.height;
    if (
      JSON.stringify(crop) ===
      JSON.stringify({
        width: smallerParam / 2,
        height: smallerParam / 2,
        x: img?.width / 2 - smallerParam / 4,
        y: img?.height / 2 - smallerParam / 4,
        unit: 'px',
        aspect: 1,
      })
    ) {
      setIsCroppingDone(true);
    }
  }, [crop, imgRef.current?.height, imgRef.current?.width]);

  const generateDownload = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob) => {
        const croppedImage = new File([blob], selectedImage?.name);
        const formData = new FormData();
        formData.append('file', croppedImage);
        dispatch(setIsUserAvatarLoading(true));
        handleClose();
        updateUserAvatar(formData)
          .then(() => {
            dispatch(setUserAvatar());
          })
          .catch(() => dispatch(setIsUserAvatarLoading(false)));
      },
      'image/png',
      1
    );
  };
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  return (
    <Modal show={isCropping} onHide={handleClose} centered backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body className='image-cropper-modal'>
        {hasImageLoaded && isCroppingDone ? (
          <>
            <label>Original Image</label>
            <ReactCrop
              circularCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
            />

            <label>Cropped Image</label>
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                  borderRadius: '50%',
                }}
              />
            </div>

            <button
              className='custom-button'
              type='button'
              disabled={!completedCrop?.width || !completedCrop?.height}
              onClick={() =>
                generateDownload(previewCanvasRef.current, completedCrop)
              }
            >
              upload
            </button>
          </>
        ) : (
          <PicLoader />
        )}
      </Modal.Body>
    </Modal>
  );
};
export default ImageCropper;
