import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAddingDomain } from '../../../redux/actions/competitor-domain';
import { GROUPER_PATH } from '../../../constants/paths';
import './InputText.scss';
const InputText = ({
  type,
  children,
  label,
  stylingClass,
  state,
  setState,
  onEnterPress,
  disabled,
  isTargetDomain,
  onFocus,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const manageDomainsCheck =
    children?.props?.className === 'manage-domains-icon';

  const dispatch = useDispatch();
  const isStartJobButtonClicked = useSelector(
    (state) => state?.jobFields?.isStartJobButtonClicked
  );
  const jobType = useSelector((state) => state?.jobDetails?.jobType);
  const competitorDomain = useSelector(
    (state) => state?.competitorDomains?.competitorDomain
  );
  const onManageDomainClick = () => {
    if (manageDomainsCheck) {
      dispatch(setIsAddingDomain(true));
    }
  };

  const isInvalid =
    (label === 'Target Domain' &&
      (jobType === GROUPER_PATH ? isTargetDomain : true) &&
      isStartJobButtonClicked &&
      !state) ||
    (label !== 'Competitor Domains' &&
      label !== 'Target Domain' &&
      isStartJobButtonClicked &&
      !state) ||
    (label === 'Competitor Domains' &&
      isStartJobButtonClicked &&
      competitorDomain);

  return (
    <Form.Group className={`pos-rel ${stylingClass}`}>
      <Form.Label
        className={
          stylingClass === 'login-input-label'
            ? 'login-input-label'
            : 'input-text-label'
        }
      >
        {label}
      </Form.Label>
      <div className='d-flex ai-center pos-rel'>
        <Form.Control
          min={1}
          disabled={disabled}
          type={
            type === 'password' && !showPassword
              ? 'password'
              : type === 'password' && showPassword
              ? 'text'
              : type === 'number'
              ? 'number'
              : 'text'
          }
          value={state}
          onFocus={onFocus}
          onChange={setState}
          onClick={onManageDomainClick}
          placeholder={manageDomainsCheck ? 'Manage Competitor Domains' : ''}
          className={`${disabled ? 'disabled-' : ''}input-text ${
            manageDomainsCheck ? 'manage-domains-input' : ''
          } ${isInvalid ? 'error-input-text' : ''}`}
          onKeyPress={(e) => {
            if (
              e?.key === 'Enter' &&
              (label === 'Competitor Domains' ||
                label === 'PASSWORD' ||
                label === 'CONFIRM PASSWORD' ||
                label === 'NEW CONFIRM PASSWORD' ||
                label === 'NEW PASSWORD')
            ) {
              onEnterPress();
            }
          }}
        />
        {type === 'password' && state && (
          <i
            className={`fa fa-eye${
              showPassword ? '-slash' : ''
            } show-password-icon`}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      {children && (
        <div
          className={`${disabled ? 'disabled-icon' : ''} ${
            manageDomainsCheck ? 'manage' : 'add'
          }-icon-container`}
        >
          {children}
        </div>
      )}
    </Form.Group>
  );
};

export default InputText;
