import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import logoShape from '../../assets/img/logo-shape.svg';
import agLogo from '../../assets/img/ag-logo.svg';
import InputText from '../Common/InputText/InputText';
import Loading from '../Common/Loading/Loading';
import { registerUser } from '../../utils/api';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Error from '../Common/Error/Error';

const Signup = ({ user }) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [didSignupBtnClick, setDidSignupBtnClick] = useState(false);
  const { push } = useHistory();

  const onSignUp = () => {
    setDidSignupBtnClick(true);

    if (
      name.trim() &&
      password.trim()?.length >= 6 &&
      password.trim() === confirmPassword.trim() &&
      user?.email
    ) {
      setInProgress(true);
      registerUser({ email: user?.email, name, password })
        .then(() => {
          setInProgress(false);
          setDidSignupBtnClick(false);
          push('/login');
        })
        .catch(() => {
          setInProgress(false);
          setDidSignupBtnClick(false);
          toast.error('Signup failed.');
        });
    }
  };
  return (
    <div className='login-container'>
      <img
        src={logoShape}
        alt='Ardent Growth Logo'
        loading='lazy'
        className='logo-shape'
      />
      <Card className='login-card'>
        <Card.Header className=' login-card-header'>
          <div>Sign Up</div>
          <div>
            <img src={agLogo} width='30' height='30' alt='ardent-growth' />
          </div>
        </Card.Header>
        <Card.Body className='login-card-body'>
          <Form autoComplete='off'>
            <InputText
              label='EMAIL'
              state={user?.email}
              stylingClass='login-input-label'
              disabled
            />
            <InputText
              label='NAME'
              state={name}
              setState={(e) => setName(e?.target?.value)}
              stylingClass='login-input-label'
            />
            <Error
              message='Please enter name here'
              isVisible={didSignupBtnClick && !name.trim()}
            />

            <InputText
              type='password'
              label='PASSWORD'
              state={password}
              setState={(e) => setPassword(e?.target?.value)}
              stylingClass='login-input-label'
              onEnterPress={onSignUp}
            />
            <Error
              message='Password should be at least 6 characters long'
              isVisible={
                (didSignupBtnClick || password.trim()) &&
                password.trim()?.length < 6
              }
            />

            <InputText
              type='password'
              label='CONFIRM PASSWORD'
              state={confirmPassword}
              setState={(e) => setConfirmPassword(e?.target?.value)}
              stylingClass='login-input-label'
              onEnterPress={onSignUp}
            />
            <Error
              message='Passwords does not match'
              isVisible={
                didSignupBtnClick &&
                password.trim() &&
                password.trim() !== confirmPassword.trim()
              }
            />
          </Form>
          {!inProgress ? (
            <div className='signin-btn-container'>
              <button className='signin-button' onClick={onSignUp}>
                Sign Up
              </button>
            </div>
          ) : (
            <Loading whichLoader='login' />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Signup;
