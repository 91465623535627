import { styled, Switch } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import './CustomToggleButton.scss';
const CustomToggleButton = ({ label, stateValue, stateAction }) => {
  const dispatch = useDispatch();
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(21px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#4E73F8',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: 'all 100ms ease',
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  return (
    <div className='custom-toggle-button'>
      <AntSwitch
        value={stateValue}
        defaultChecked={stateValue}
        onChange={(e) => dispatch(stateAction(e?.target?.checked))}
        inputProps={{ 'aria-label': 'ant design' }}
      />
      <div className='label'>{label}</div>
    </div>
  );
};

export default CustomToggleButton;
