import React from 'react';
import './EmptyTable.scss';
import nodata from '../../../assets/img/no-data.svg';
const EmptyTable = ({ text }) => (
  <div className='empty-table'>
    <img
      src={nodata}
      alt='No Data'
      className='no-data'
      title='No Data'
      height='150px'
    />
    <div className='empty-table-text'>{text}</div>
  </div>
);

export default EmptyTable;
