import { ACTIVE_JOBS_PATH } from '../../constants/paths';
import { SET_JOB_TYPE } from '../action-types/job-details';

const initialState = {
  jobType: ACTIVE_JOBS_PATH,
};
export const jobDetailsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_JOB_TYPE: {
      return {
        ...state,
        jobType: payload,
      };
    }
    default:
      return state;
  }
};
