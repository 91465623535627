import React, { useCallback, useEffect, useState } from 'react';
import { verifyToken } from '../../utils/api';
import { removeAppUser } from '../../utils/helpers';
import Loading from '../Common/Loading/Loading';
import Signup from './Signup';
import './styles.scss';
const Verification = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const [user, setUser] = useState({});
  const verifyTokenHandler = useCallback(() => {
    removeAppUser();
    verifyToken({ token }).then((response) => {
      setUser(response?.data?.user);
    });
  }, [token]);
  useEffect(() => {
    verifyTokenHandler();
  }, [verifyTokenHandler]);
  return (
    <div className='verification-screen'>
      {!user ? <Loading whichLoader='login' /> : <Signup user={user} />}
    </div>
  );
};

export default Verification;
