import React, { useState } from 'react';
import { runGrouper } from '../../../utils/api';
import './KeywordGrouper.scss';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import InputText from '../../Common/InputText/InputText';
import { useDispatch, useSelector } from 'react-redux';
import {
  setisStartJobButtonClicked,
  setReportTitle,
  setTargetDomain,
  setDocumentFile,
  setGroupingMethod,
  setMainKeywordGroupingAccuracy,
  setVariantKeywordGroupingAccuracy,
  setPositionThreshold,
  setVariantPositionThreshold,
  setSendingFileProgress,
  // setNoOfClusters,
} from '../../../redux/actions/job-fields';
import {
  setCompetitorDomain,
  setCompetitorDomainsList,
  setIsAddingDomain,
} from '../../../redux/actions/competitor-domain';
import CompetitorDomain from '../../Common/CompetitorDomains/CompetitorDomain';
import InputSelect from '../../Common/InputSelect/InputSelect';
import { ACTIVE_JOBS_PATH } from '../../../constants/paths';
import Heading from '../../Common/Heading/Heading';
import { setJobType } from '../../../redux/actions/job-details';
import DropFile from '../../Common/DropFile/DropFile';
const KeywordGrouper = () => {
  const {
    reportTitle,
    targetDomain,
    groupingMethod,
    mainKeywordGroupingAccuracy,
    variantKeywordGroupingAccuracy,
    positionThreshold,
    variantPositionThreshold,
    isStartJobButtonClicked,
    documentFile,
    noOfClusters,
    sendingFileProgress,
  } = useSelector((state) => state?.jobFields);
  const competitorDomainsList = useSelector(
    (state) => state?.competitorDomains?.competitorDomainsList
  );
  const isAddingDomain = useSelector(
    (state) => state?.competitorDomains?.isAddingDomain
  );
  const competitorDomain = useSelector(
    (state) => state?.competitorDomains?.competitorDomain
  );
  const editedCompetitorDomain = useSelector(
    (state) => state?.competitorDomains?.editedCompetitorDomain
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [isJobPreparedToStart, setIsJobPreparedToStart] = useState(false);
  const [isTargetDomain, setIsTargetDomain] = useState(false);

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const getKeywords = () => {
    dispatch(setisStartJobButtonClicked(true));
    if (
      reportTitle.trim() &&
      groupingMethod &&
      (groupingMethod === 'Main + Variants'
        ? variantKeywordGroupingAccuracy
        : mainKeywordGroupingAccuracy) &&
      documentFile &&
      (!isTargetDomain || (isTargetDomain && targetDomain)) &&
      !competitorDomain &&
      !editedCompetitorDomain
      // &&
      // noOfClusters > 0
    ) {
      setIsJobPreparedToStart(true);
      const formData = new FormData();
      let tempObj = {
        report_title: reportTitle,
        grouping_method: groupingMethod,
        main_keyword_grouping_accuracy: mainKeywordGroupingAccuracy,
        no_of_clusters: noOfClusters,
        organic_results_count:
          groupingMethod === 'Main + Variants'
            ? variantPositionThreshold
            : positionThreshold,
      };
      if (groupingMethod === 'Main + Variants') {
        tempObj = {
          ...tempObj,
          variant_keyword_grouping_accuracy: variantKeywordGroupingAccuracy,
        };
      }

      const request = JSON.stringify(
        !isTargetDomain
          ? tempObj
          : {
              ...tempObj,
              target_domain: targetDomain,
              competitor_domains: competitorDomainsList?.map(
                (dom) => dom?.text
              ),
            }
      );
      formData.append('request', request);
      formData.append('file', documentFile);
      runGrouper(formData, (progress) =>
        dispatch(setSendingFileProgress(progress))
      )
        .then(() => {
          toast.success('Grouper job has started.');
          setIsJobPreparedToStart(false);
          dispatch(setSendingFileProgress(null));
          dispatch(setisStartJobButtonClicked(false));
          dispatch(setJobType(ACTIVE_JOBS_PATH));
          history.push(ACTIVE_JOBS_PATH);
        })
        .catch((error) => {
          setIsJobPreparedToStart(false);
          toast.error(error?.response?.data?.message);
          dispatch(setSendingFileProgress(null));
          dispatch(setisStartJobButtonClicked(false));
        });
    }
  };

  const addDomain = () => {
    if (competitorDomainsList.length < 10 && competitorDomain) {
      dispatch(
        setCompetitorDomainsList([
          ...competitorDomainsList,
          {
            text: competitorDomain,
            id: Date.now(),
          },
        ])
      );
      dispatch(setCompetitorDomain(''));
    }
  };

  const isDisabled =
    isAddingDomain !== false && competitorDomainsList?.length === 10;
  return (
    <>
      <Heading text='Keyword Grouper' />
      <div className='inputs-form'>
        <div className='main-description'>
          <div>Using the Grouper</div>
          <div>Used to group the set of keywords you’ve provided.</div>
        </div>
        <div className='domains-container'>
          <InputText
            label='Report Title'
            state={reportTitle}
            setState={(e) => dispatch(setReportTitle(e?.target?.value))}
            stylingClass='report-title-input-noc'
          />
          {/* <InputText
          label='No. of Clusters'
          state={noOfClusters}
          type='number'
          setState={(e) =>
            dispatch(setNoOfClusters(parseInt(e?.target?.value)))
          }
          stylingClass='no-clusters-input'
        /> */}

          <div className='empty-container'>&nbsp;</div>
        </div>
        <label className='custom-checkbox-container change-target-domain-container'>
          <div>
            <input
              type='checkbox'
              className='custom-checkbox'
              onChange={() => {
                setIsTargetDomain(!isTargetDomain);
                dispatch(setIsAddingDomain(false));
                dispatch(setCompetitorDomainsList([]));
                dispatch(setCompetitorDomain(''));
              }}
              checked={isTargetDomain}
            />
            <span className='custom-checkmark' />
          </div>
          <div className='change-target-domain-text'>
            Change target and competitor domains
          </div>
        </label>
        <div className='domains-container'>
          <InputText
            disabled={!isTargetDomain}
            label='Target Domain'
            state={targetDomain}
            setState={(e) => dispatch(setTargetDomain(e?.target?.value))}
            stylingClass='target-domain-input'
            isTargetDomain={isTargetDomain}
          />
          <InputText
            label='Competitor Domains'
            state={competitorDomain}
            setState={(e) => dispatch(setCompetitorDomain(e?.target?.value))}
            stylingClass='competitor-domains-input'
            onEnterPress={addDomain}
            disabled={
              !isTargetDomain ||
              (isAddingDomain !== false && competitorDomainsList?.length === 10)
            }
            onFocus={() => dispatch(setIsAddingDomain(true))}
          >
            {isAddingDomain ? (
              <img
                alt='add-domain'
                src={
                  isDisabled
                    ? require('../../../assets/img/disabled-add-domain-icon.svg')
                        .default
                    : require('../../../assets/img/add-domain-icon.svg').default
                }
                className={`${isDisabled ? 'disabled' : ''}add-domain-icon`}
                onClick={addDomain}
              />
            ) : (
              <img
                alt='manage-domains-icon'
                src={
                  !isTargetDomain ||
                  (isAddingDomain !== false &&
                    competitorDomainsList?.length === 10)
                    ? require(`../../../assets/img/disabled-clarity-network-settings-line.svg`)
                        .default
                    : require(`../../../assets/img/clarity-network-settings-line.svg`)
                        .default
                }
                className='manage-domains-icon'
              />
            )}
          </InputText>
        </div>

        {competitorDomainsList?.length > 0 && isAddingDomain && (
          <div className='d-flex jc-sp ai-center'>
            <div className='w-49' />
            <div className='w-49 competitor-card'>
              <div className='tags-card'>
                {competitorDomainsList?.map(({ id, text }) => (
                  <CompetitorDomain key={id} domain={{ id, text }} />
                ))}
              </div>
              <div className='tags-card-footer'>
                <div
                  className='footer-close-button'
                  onClick={() => {
                    dispatch(setIsAddingDomain(false));
                    dispatch(setCompetitorDomain(''));
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='search-engine-container'>
          <div className='sub-heading-text'>Grouping settings</div>
          <InputSelect
            label='Grouping Method'
            options={['Main Keyword Only', 'Main + Variants']}
            state={groupingMethod}
            setState={(value) => dispatch(setGroupingMethod(value))}
          />
          <div className='select-box-container'>
            <div className='select-box-sub-container'>
              <label
                className={`select-box-label-visible input-select-label ${
                  isStartJobButtonClicked && !mainKeywordGroupingAccuracy
                    ? 'error-label'
                    : ''
                }`}
              >
                Main Keyword Grouping Accuracy
              </label>
              <div className='radio-box-container'>
                {numbers?.map((number) => (
                  <label key={number} className='radio-label'>
                    <input
                      type='radio'
                      className='grouping-radio-box'
                      name='main-selector'
                      value={number}
                      checked={mainKeywordGroupingAccuracy === number}
                      onChange={() =>
                        dispatch(setMainKeywordGroupingAccuracy(number))
                      }
                    />
                    <span
                      key={number}
                      className={`checkbox-number ${
                        isStartJobButtonClicked && !mainKeywordGroupingAccuracy
                          ? 'error-label'
                          : ''
                      }`}
                    >
                      {number}
                    </span>
                  </label>
                ))}
              </div>
            </div>
            {groupingMethod === 'Main + Variants' ? (
              <div className='select-box-sub-container'>
                <label
                  className={`select-box-label-visible ${
                    isStartJobButtonClicked && !mainKeywordGroupingAccuracy
                      ? 'error-label'
                      : ''
                  }`}
                >
                  Variant Keyword Grouping Accuracy
                </label>
                <div className='radio-box-container'>
                  {numbers?.map((number) => (
                    <label key={number} className='radio-label'>
                      <input
                        type='radio'
                        className='grouping-radio-box'
                        name='variant-selector'
                        value={variantKeywordGroupingAccuracy}
                        checked={variantKeywordGroupingAccuracy === number}
                        onChange={() =>
                          dispatch(setVariantKeywordGroupingAccuracy(number))
                        }
                      />
                      <span
                        key={number}
                        className={`checkbox-number ${
                          isStartJobButtonClicked &&
                          !variantKeywordGroupingAccuracy
                            ? 'error-label'
                            : ''
                        }`}
                      >
                        {number}
                      </span>
                    </label>
                  ))}
                </div>
                <div className='select-box-sub-container'></div>
              </div>
            ) : (
              <div className='select-box-sub-container'>
                <label
                  className={`select-box-label-visible input-select-label ${
                    isStartJobButtonClicked && !positionThreshold
                      ? 'error-label'
                      : ''
                  }`}
                >
                  Position Threshold
                </label>
                <div className='radio-box-container'>
                  {numbers?.map((number) => (
                    <label key={number} className='radio-label'>
                      <input
                        type='radio'
                        className='grouping-radio-box'
                        name='variant-selector'
                        value={positionThreshold}
                        checked={positionThreshold === number}
                        onChange={() => dispatch(setPositionThreshold(number))}
                      />
                      <span
                        key={number}
                        className={`checkbox-number ${
                          isStartJobButtonClicked &&
                          !mainKeywordGroupingAccuracy
                            ? 'error-label'
                            : ''
                        }`}
                      >
                        {number}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className='select-box-container'>
            <div className='select-box-sub-container' />
            {groupingMethod === 'Main + Variants' && (
              <div className='select-box-sub-container'>
                <label
                  className={`select-box-label-visible ${
                    isStartJobButtonClicked && !variantPositionThreshold
                      ? 'error-label'
                      : ''
                  }`}
                >
                  Variant Position Threshold
                </label>
                <div className='radio-box-container'>
                  {numbers?.map((number) => (
                    <label key={number} className='radio-label'>
                      <input
                        type='radio'
                        className='grouping-radio-box'
                        name='variant-threshold-selector'
                        value={variantPositionThreshold}
                        checked={variantPositionThreshold === number}
                        onChange={() =>
                          dispatch(setVariantPositionThreshold(number))
                        }
                      />
                      <span
                        key={number}
                        className={`checkbox-number ${
                          isStartJobButtonClicked && !variantPositionThreshold
                            ? 'error-label'
                            : ''
                        }`}
                      >
                        {number}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <label className='sub-heading-text'>Input File</label>
          <div className='file-upload-container'>
            <span className='file-upload-text'>
              Load your Ahrefs export from keywords explorer below.
            </span>
            <DropFile
              setDocumentFile={(file) => dispatch(setDocumentFile(file))}
              fileName={documentFile?.name}
            />
          </div>
        </div>
        <div className='button-container'>
          <button
            className={`custom-button ${
              isJobPreparedToStart && sendingFileProgress === null
                ? 'loader-button'
                : ''
            }`}
            onClick={!isJobPreparedToStart ? getKeywords : () => ''}
          >
            Start Grouping
          </button>
        </div>
      </div>
    </>
  );
};
export default KeywordGrouper;
