import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PUBLIC_URL } from '../../../constants/environment-variables';
import PulsatingDot from '../PulsatingDot/PulsatingDot';
import { combinedKeys, fetcherKeys, grouperKeys, hubKeys } from './job-keys';
import Loading from '../../Common/Loading/Loading';
import { downloadFileFromCloud, resumeJob } from '../../../utils/api';
import { INPUT_FILE_PATH } from '../../../constants/socket';
import useCustomSocket from '../../../hooks/useCustomSocket';
import { toast } from 'react-toastify';
import { isChangingTextCase } from '../../../utils/helpers';
import { Link } from 'react-router-dom';
import downloadIcon from '../../../assets/img/download-icon.svg';
import { setJobType } from '../../../redux/actions/job-details';
import { setSelectedAdminScreen } from '../../../redux/actions/sidebar';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
import { useDispatch } from 'react-redux';
const JobDetails = ({ details, isLoading, jobState }) => {
  const { jobType, userId } = useParams();
  const [downloadType, setDownlaodType] = useState(null);
  const [jobDetails, setJobDetails] = useState([]);
  const [inputFilePath, setInputFilePath] = useState('');
  const [toggledCreationTimes, setToggledCreationTimes] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  const dispatch = useDispatch();
  const updateInputFilePath = useCallback(({ path }) => {
    setInputFilePath(path);
  }, []);

  const toggleCreationTime = (id) => {
    if (toggledCreationTimes?.includes(id)) {
      setToggledCreationTimes(
        toggledCreationTimes?.filter((jobId) => jobId !== id)
      );
    } else {
      setToggledCreationTimes([...toggledCreationTimes, id]);
    }
  };

  const socketInputPath = (message) => {
    if (message.event === INPUT_FILE_PATH) {
      updateInputFilePath(message);
    }
  };
  useCustomSocket(socketInputPath);

  const jobKeys =
    jobType === 'fetcher'
      ? fetcherKeys
      : jobType === 'grouper'
      ? grouperKeys
      : jobType === 'hub'
      ? hubKeys
      : combinedKeys;

  useEffect(() => {
    setJobDetails(details?.filter(({ key }) => jobKeys?.includes(key)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);
  useEffect(() => {
    if (userId) {
      dispatch(setJobType(`${ADMIN_CONSOLE_PATH}/users`));
      dispatch(setSelectedAdminScreen('users'));
    } else {
      dispatch(setJobType(`/jobs/${jobState}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getDesiredValue = (key) =>
    details?.find((item) => item?.key === key)?.value;

  const jobTitle = getDesiredValue('report_title');
  const isArchived = getDesiredValue('is_archived');
  const type = getDesiredValue('type');
  const jobId = getDesiredValue('id');
  const input_file_path = getDesiredValue('input_file_path');

  const fetcher_upload_file_path = getDesiredValue('fetcher_upload_file_path');
  const fetcher_bulk_upload_file_path = getDesiredValue(
    type === 'combined' ? 'fetcher_bulk_upload_file_path' : 'bulk_path'
  );
  const fetcherPathFromSocket = getDesiredValue('fetcherPathFromSocket');
  const fetcherBulkPathFromSocket = getDesiredValue(
    'fetcherBulkPathFromSocket'
  );
  const final_upload_file_path = getDesiredValue('final_upload_file_path');
  const hub_upload_file_path = getDesiredValue('hub_upload_file_path');
  const hubCreatorPathFromSocket = getDesiredValue('hubCreatorPathFromSocket');
  const grouperPathFromSocket = getDesiredValue('grouperPathFromSocket');
  const status = getDesiredValue('status');

  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, []);

  const downloadFile = ({ id, reportType }) => {
    downloadFileFromCloud({ id, reportType })
      .then((response) => {
        window.open(response?.data?.url, '_self');
      })
      .catch((error) => toast.error(error?.response?.data?.message));
  };

  const areDetailsValid = (details) => {
    if (Array.isArray(details)) {
      return details?.length > 0;
    } else {
      return !!details;
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          {jobTitle && type && status ? (
            <div className='job-details-card'>
              <div className='job-details-card-header'>
                <h3>{jobTitle}</h3>
                <div>
                  {getDesiredValue('reason_of_failure') &&
                    jobType !== 'grouper' && (
                      <button
                        onClick={() => resumeJob(jobId)}
                        className='custom-button download-btn'
                        style={{ marginRight: '1rem' }}
                      >
                        RESUME
                      </button>
                    )}
                  <Link
                    to={
                      !userId
                        ? `${PUBLIC_URL}/jobs/${
                            isArchived ? 'archived' : 'active'
                          }/${type}/${jobId}/details/logs`
                        : `${PUBLIC_URL}/jobs/${userId}/${
                            isArchived ? 'archived' : 'active'
                          }/${type}/${jobId}/details/logs`
                    }
                    className='custom-anchor'
                  >
                    <button className='custom-button download-btn'>
                      VIEW LOGS
                    </button>
                  </Link>
                </div>
              </div>
              <div className='job-details-card-body'>
                <div className='details-container'>
                  {jobDetails?.map(({ key, value }) => (
                    <>
                      {areDetailsValid(value) && (
                        <div className='item-details'>
                          <div className='details-key'>
                            {key.replace(/_/g, ' ')}
                          </div>
                          <div className='details-value'>
                            {key === 'status' ? (
                              <div className='jobs-status'>
                                <PulsatingDot status={value} />
                                {value}
                              </div>
                            ) : ['created_at', 'updated_at']?.includes(key) ? (
                              <span
                                onClick={() => toggleCreationTime(value)}
                                className='created-at-td'
                              >
                                {toggledCreationTimes?.includes(value)
                                  ? moment(value).format(
                                      'MM/DD/YY - hh:mm a'
                                    ) ?? '-'
                                  : moment(value).from(moment(currentTime)) ??
                                    '-'}
                              </span>
                            ) : (
                              <span
                                className={
                                  isChangingTextCase(key) ? 'initcap-case' : ''
                                }
                              >
                                {key === 'competitor_domains'
                                  ? value.toString()
                                  : value}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className='text-muted job-details-card-footer'>
                <div className='download-buttons-container'>
                  <div
                    className='radio-button-container'
                    onClick={() => {
                      if (input_file_path || inputFilePath)
                        setDownlaodType('input');
                    }}
                  >
                    <input
                      type='radio'
                      id='input'
                      name='radio-group'
                      disabled={!input_file_path && !inputFilePath}
                    />
                    <label for='input'>input</label>
                  </div>
                  {(type === 'combined' || type === 'fetcher') && (
                    <div
                      className='radio-button-container'
                      onClick={() => {
                        if (
                          status === 'completed' &&
                          (fetcher_bulk_upload_file_path ||
                            fetcherBulkPathFromSocket)
                        ) {
                          setDownlaodType(
                            type === 'combined' ? 'fetcherBulk' : 'bulk'
                          );
                        }
                      }}
                    >
                      <input
                        type='radio'
                        id='bulk'
                        name='radio-group'
                        disabled={
                          status !== 'completed' &&
                          (!fetcher_bulk_upload_file_path ||
                            !fetcherBulkPathFromSocket)
                        }
                      />
                      <label for='bulk'>bulk</label>
                    </div>
                  )}
                  {type === 'combined' && (
                    <div
                      className='radio-button-container'
                      onClick={() => {
                        if (fetcher_upload_file_path || fetcherPathFromSocket)
                          setDownlaodType('intermediate');
                      }}
                    >
                      <input
                        type='radio'
                        id='fetcher'
                        name='radio-group'
                        disabled={
                          !fetcher_upload_file_path && !fetcherPathFromSocket
                        }
                      />
                      <label for='fetcher'>fetcher</label>
                    </div>
                  )}
                  {type !== 'hub' && (
                    <div
                      className='radio-button-container'
                      onClick={() => {
                        if (
                          status === 'completed' &&
                          ((type === 'combined' &&
                            (final_upload_file_path ||
                              grouperPathFromSocket)) ||
                            ['grouper', 'fetcher']?.includes(type))
                        ) {
                          setDownlaodType('final');
                        }
                      }}
                    >
                      <input
                        type='radio'
                        id='grouper'
                        name='radio-group'
                        disabled={
                          !(
                            status === 'completed' &&
                            ((type === 'combined' &&
                              (final_upload_file_path ||
                                grouperPathFromSocket)) ||
                              ['grouper', 'fetcher']?.includes(type))
                          )
                        }
                      />
                      <label for='grouper'>
                        {type === 'combined' ? 'grouper' : type}
                      </label>
                    </div>
                  )}
                  {type === 'hub' && (
                    <div
                      className='radio-button-container'
                      onClick={() => setDownlaodType('hub')}
                    >
                      <input
                        type='radio'
                        id='hub'
                        name='radio-group'
                        disabled={
                          !hubCreatorPathFromSocket && !hub_upload_file_path
                        }
                      />
                      <label for='hub'>HUB</label>
                    </div>
                  )}
                  <button
                    className={`custom-button download-btn download-report-button ${
                      downloadType === null ? 'disabled-button' : ''
                    }`}
                    title={`Job ${
                      status === 'failed' ? 'has' : 'is'
                    } ${status}`}
                    onClick={downloadFile.bind(this, {
                      id: jobId,
                      reportType: downloadType,
                    })}
                  >
                    <img src={downloadIcon} alt='download' />{' '}
                    <span>Download</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default JobDetails;
