import { validImageFormats } from '../../constants';

export const isChangingTextCase = (label) =>
  ![
    'target_domain',
    'competitor_domains',
    'created_at',
    'updated_at',
  ]?.includes(label);
const getDesiredValue = (jobDetails, label) => ({
  key:
    label === '_id'
      ? 'id'
      : label === 'createdAt'
      ? 'created_at'
      : label === 'updatedAt'
      ? 'updated_at'
      : label === 'no_of_clusters'
      ? 'number_of_clusters'
      : label,
  value: jobDetails[label],
});
export const populateJobDetails = (detailProps, jobDetails) => {
  detailProps.push(getDesiredValue(jobDetails, '_id'));
  detailProps.push(getDesiredValue(jobDetails, 'grouping_method'));
  detailProps.push(getDesiredValue(jobDetails, 'target_domain'));
  detailProps.push(
    getDesiredValue(jobDetails, 'main_keyword_grouping_accuracy')
  );
  detailProps.push(getDesiredValue(jobDetails, 'organic_results_count'));
  detailProps.push(getDesiredValue(jobDetails, 'no_of_clusters'));
  detailProps.push(
    getDesiredValue(jobDetails, 'variant_keyword_grouping_accuracy')
  );
  detailProps.push(getDesiredValue(jobDetails, 'search_engine'));
  detailProps.push(getDesiredValue(jobDetails, 'country'));
  detailProps.push(getDesiredValue(jobDetails, 'location'));
  detailProps.push(getDesiredValue(jobDetails, 'language'));
  detailProps.push(getDesiredValue(jobDetails, 'type'));
  detailProps.push(getDesiredValue(jobDetails, 'createdAt'));
  detailProps.push(getDesiredValue(jobDetails, 'updatedAt'));
  detailProps.push(getDesiredValue(jobDetails, 'reason_of_failure'));
  detailProps.push(getDesiredValue(jobDetails, 'report_title'));
  detailProps.push(getDesiredValue(jobDetails, 'is_archived'));
  detailProps.push(getDesiredValue(jobDetails, 'input_file_path'));
  detailProps.push(getDesiredValue(jobDetails, 'fetcher_upload_file_path'));
  detailProps.push(getDesiredValue(jobDetails, 'bulk_path'));
  detailProps.push(
    getDesiredValue(jobDetails, 'fetcher_bulk_upload_file_path')
  );
  detailProps.push(getDesiredValue(jobDetails, 'hub_upload_file_path'));
  detailProps.push(getDesiredValue(jobDetails, 'final_upload_file_path'));
  detailProps.push(getDesiredValue(jobDetails, 'competitor_domains'));
};
export const getAppUser = () =>
  JSON.parse(localStorage.getItem('grouper-app-user'));

export const getSideBarStatus = () => localStorage.getItem('isSideBarOpen');

export const removeAppUser = () => localStorage.removeItem('grouper-app-user');

export const setSideBarOpenStatus = (status) =>
  localStorage.setItem('isSideBarOpen', status);

export const setAppUser = ({ userId, name, token, role }) =>
  localStorage.setItem(
    'grouper-app-user',
    JSON.stringify({ userId, name, token, role })
  );

export const isValidFormat = (value) => {
  let flag = false;
  for (let index = 0; index < validImageFormats?.length; index++) {
    flag = value?.includes(validImageFormats[index]);
    if (flag) return flag;
  }
};

export const isLinksDropdownExpanded = (array) => {
  const mappedArray = array?.map(({ path }) => path);
  let flag = false;
  for (let index = 0; index < mappedArray?.length; index++) {
    if (window?.location?.pathname?.includes(mappedArray?.[index])) {
      flag = true;
    }
  }
  return flag;
};
