import moment from 'moment';
import { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import unarchiveIcon from '../../../assets/img/unarchive-icon.svg';
import unarchiveIconGrey from '../../../assets/img/unarchive-icon-grey.svg';
import './ArchivedUsersModal.scss';
import loadMoreIconGrey from '../../../assets/img//load-more-icon-grey.svg';

import { changeUsersActiveStatus, getUsers } from '../../../utils/api';
import { setAllUsers } from '../../../redux/actions/users';
import { setDidDataLoad } from '../../../redux/actions/jobs';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { setJobType } from '../../../redux/actions/job-details';
import EmptyTable from '../EmptyTable/EmptyTable';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
import { useSortUsers } from '../../../hooks/useSortUsers';
const ArchivedUsersModal = ({ show, handleClose, onLogout }) => {
  const users = useSelector((state) => state?.users?.allUsers)?.filter(
    ({ is_archived }) => is_archived
  );
  const jobType = useSelector((state) => state?.jobDetails?.jobType);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [toggledCreationTimes, setToggledCreationTimes] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(
    users?.filter(({ is_archived }) => is_archived)
  );
  const [shownResults, setShownResults] = useState(10);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const {
    lastUsedSortFilter,
    loginSortFilter,
    sortByLastUsed,
    sortByLastLogin,
  } = useSortUsers({ users, setFilteredUsers });

  const toggleCreationTime = (id) => {
    if (toggledCreationTimes?.includes(id)) {
      setToggledCreationTimes(
        toggledCreationTimes?.filter((jobId) => jobId !== id)
      );
    } else {
      setToggledCreationTimes([...toggledCreationTimes, id]);
    }
  };
  const toggleSelection = (jobId) => {
    if (selectedUsers?.includes(jobId)) {
      const updatedJobs = selectedUsers?.filter((id) => id !== jobId);
      setSelectedUsers(updatedJobs);
    } else {
      const updatedJobs = [...selectedUsers, jobId];
      setSelectedUsers(updatedJobs);
    }
  };
  const toggleAllSelection = () => {
    const allUsers = users?.map(({ _id }) => _id);
    if (selectedUsers?.length < allUsers?.length) {
      setSelectedUsers(allUsers);
    } else {
      setSelectedUsers([]);
    }
  };
  const getAllUsers = useCallback(() => {
    getUsers().then(({ data }) => {
      const usersList = data?.map((user) => ({
        ...user?.user,
        latestJob: user?.latestJob,
      }));
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
      dispatch(setDidDataLoad(true));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const unarchivedSelectedUsers = () => {
    if (selectedUsers?.length > 0) {
      const usersToBeArchived = users?.filter(({ _id }) =>
        selectedUsers?.includes(_id)
      );
      changeUsersActiveStatus({ users: usersToBeArchived, action: 'unarchive' })
        .then(({ data }) => {
          setSelectedUsers([]);
          getAllUsers();
          toast.success(data?.message);
        })
        .catch(() => toast.error('Can not archive users.'));
    }
  };
  const viewUserDetails = (userId) => {
    dispatch(setJobType(ADMIN_CONSOLE_PATH));
    push(`/jobs/${userId}/active`);
  };
  const searchUsers = (value) => {
    if (!value) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users?.filter(({ name }) =>
          name?.toLowerCase()?.includes(value?.toLowerCase())
        )
      );
    }
  };
  const loadMore = () => setShownResults(shownResults + 10);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        getAllUsers();
      }}
      centered
      className='archived-users-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='heading-text'>ARCHIVED USERS</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={users?.length !== 0 && 'pt-half'}>
        {users?.length !== 0 ? (
          <>
            <div className='jobs-card-header d-flex jc-sp ai-center'>
              <div className='d-flex'>
                <div
                  className={`d-flex ai-center action-card ${
                    selectedUsers?.length === 0 ? 'disabled-action-card' : ''
                  } cursor-pointer`}
                  onClick={unarchivedSelectedUsers}
                >
                  <img
                    src={
                      jobType === '/admin/console/users' &&
                      selectedUsers?.length === 0
                        ? unarchiveIconGrey
                        : jobType === '/admin/console/users' &&
                          selectedUsers?.length !== 0
                        ? unarchiveIcon
                        : 'd'
                    }
                    alt='archive'
                    className='action-bar-icon'
                  />
                  Unarchive Selected
                </div>
              </div>

              <div>
                <div className='d-flex ai-center action-card right'>
                  <div className='ui search searchbar-container'>
                    <div className='ui icon input'>
                      <input
                        className='prompt'
                        type='text'
                        placeholder='Search User'
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e?.target?.value);
                          searchUsers(e?.target?.value);
                        }}
                      />
                      <i className='search icon' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table
              border={1}
              cellPadding={20}
              cellSpacing={10}
              className='jobs-table user-table'
            >
              <thead>
                <th>
                  <label className='custom-checkbox-container'>
                    <input
                      type='checkbox'
                      className='custom-checkbox'
                      onClick={toggleAllSelection}
                      checked={
                        users?.length > 0 &&
                        users?.length === selectedUsers?.length
                      }
                    />
                    <span className='custom-checkmark' />
                  </label>
                </th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>API CREDITS</th>
                <th>CREATED</th>
                <th
                  className='cursor-pointer'
                  style={
                    lastUsedSortFilter === 'default' ? {} : { color: 'black' }
                  }
                  onClick={sortByLastUsed}
                >
                  LAST USED{' '}
                  {lastUsedSortFilter === 'desc' ? (
                    <>&#8593;</>
                  ) : lastUsedSortFilter === 'asc' ? (
                    <>&#8595;</>
                  ) : null}
                </th>
                <th
                  className='cursor-pointer'
                  style={
                    loginSortFilter === 'default' ? {} : { color: 'black' }
                  }
                  onClick={sortByLastLogin}
                >
                  LAST LOGIN
                  {loginSortFilter === 'desc' ? (
                    <>&#8593;</>
                  ) : loginSortFilter === 'asc' ? (
                    <>&#8595;</>
                  ) : null}
                </th>
                <th />
              </thead>
              <tbody>
                {filteredUsers?.map(
                  (
                    {
                      username,
                      _id,
                      name,
                      api_credits,
                      job_count,
                      createdAt,
                      is_archived,
                      latestJob,
                      last_login,
                    },
                    index
                    // eslint-disable-next-line array-callback-return
                  ) => {
                    if (is_archived) {
                      return (
                        <tr key={_id}>
                          <td>
                            <label className='custom-checkbox-container'>
                              <input
                                type='checkbox'
                                className='custom-checkbox'
                                onClick={() => toggleSelection(_id)}
                                checked={selectedUsers?.includes(_id)}
                              />
                              <span className='custom-checkmark' />
                            </label>
                          </td>
                          <td>{name}</td>
                          <td>{username}</td>
                          <td>{api_credits}</td>
                          <td
                            onClick={() => toggleCreationTime(_id)}
                            className='created-at-td'
                          >
                            {toggledCreationTimes?.includes(_id)
                              ? moment(createdAt).format('MM/DD/YY - hh:mm a')
                              : moment(createdAt).fromNow()}
                          </td>
                          <td>
                            {latestJob ? moment(latestJob).fromNow() : '-'}
                          </td>
                          <td>
                            {last_login ? moment(last_login).fromNow() : '-'}
                          </td>

                          <td>
                            <button
                              className='custom-button download-btn'
                              onClick={() => viewUserDetails(_id)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  }
                )}
              </tbody>
            </table>
            <div className='jobs-card-footer'>
              {shownResults < filteredUsers?.length && (
                <span className='load-more-btn' onClick={loadMore}>
                  <img src={loadMoreIconGrey} alt='load-more' />
                  Load more...
                </span>
              )}
            </div>
          </>
        ) : (
          <EmptyTable text='No archived user' />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ArchivedUsersModal;
