import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Heading from '../../Common/Heading/Heading';
import binIcon from '../../../assets/img/bin-icon.svg';
import binIconGrey from '../../../assets/img/bin-icon-grey.svg';
import refreshIcon from '../../../assets/img/refresh-icon.svg';
import refreshIconGrey from '../../../assets/img/refresh-icon-grey.svg';
import inviteIcon from '../../../assets/img/invite-icon.svg';
import moment from 'moment';
import loadMoreIconGrey from '../../../assets/img//load-more-icon-grey.svg';
import EmptyTable from '../../Common/EmptyTable/EmptyTable';
import Loading from '../../Common/Loading/Loading';
import {
  deletePendingInvites,
  getPendingInvitations,
  resendPendingInvites,
} from '../../../utils/api';
import { setPendingInvites } from '../../../redux/actions/auth';
import { setDidDataLoad } from '../../../redux/actions/jobs';
import InvitePopover from '../../Common/InvitePopover/InvitePopover';
import { toast } from 'react-toastify';
import { setJobType } from '../../../redux/actions/job-details';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
const PendingInvites = () => {
  const pendingInvites = useSelector((state) => state?.auth?.pendingInvites);
  const [filteredPendingInvites, setFilteredPendingInvites] =
    useState(pendingInvites);
  const [selectedPendingInvites, setSelectedPendingInvites] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [toggledCreationTimes, setToggledCreationTimes] = useState([]);
  const [shownResults, setShownResults] = useState(10);
  const didDataLoad = useSelector((state) => state?.jobs?.didDataLoad);
  const jobType = useSelector((state) => state?.jobDetails?.jobType);

  const dispatch = useDispatch();

  const { push } = useHistory();
  const getAllPendingInvites = useCallback(() => {
    push(`${ADMIN_CONSOLE_PATH}/pending-invites`);
    getPendingInvitations().then(({ data }) => {
      dispatch(setPendingInvites(data));
      dispatch(setDidDataLoad(true));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push]);
  useEffect(() => {
    dispatch(setJobType('/admin/console/pending-invites'));
    getAllPendingInvites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllPendingInvites]);
  useEffect(() => {
    setFilteredPendingInvites(pendingInvites);
    setSelectedPendingInvites([]);
    setSearchValue('');
    setShownResults(10);
  }, [pendingInvites]);
  const toggleSelection = (jobId) => {
    if (selectedPendingInvites?.includes(jobId)) {
      const updatedJobs = selectedPendingInvites?.filter((id) => id !== jobId);
      setSelectedPendingInvites(updatedJobs);
    } else {
      const updatedJobs = [...selectedPendingInvites, jobId];
      setSelectedPendingInvites(updatedJobs);
    }
  };
  const toggleAllSelection = () => {
    const allJobs = pendingInvites?.map(({ _id }) => _id);
    if (selectedPendingInvites?.length < allJobs?.length) {
      setSelectedPendingInvites(allJobs);
    } else {
      setSelectedPendingInvites([]);
    }
  };
  const toggleCreationTime = (id) => {
    if (toggledCreationTimes?.includes(id)) {
      setToggledCreationTimes(
        toggledCreationTimes?.filter((jobId) => jobId !== id)
      );
    } else {
      setToggledCreationTimes([...toggledCreationTimes, id]);
    }
  };
  const deleteInvites = () => {
    const invitesToBeDeleted = pendingInvites?.filter((invite) =>
      selectedPendingInvites?.includes(invite?._id)
    );
    deletePendingInvites({ invites: invitesToBeDeleted })
      .then(() => {
        getAllPendingInvites();
        setSelectedPendingInvites([]);
        toast.success('Invitations deleted.');
      })
      .catch(() => {
        toast.success('Invitations can not be deleted.');
      });
  };
  const refreshInvites = () => {
    const invitesToBeRefreshed = pendingInvites?.filter((invite) =>
      selectedPendingInvites?.includes(invite?._id)
    );
    resendPendingInvites({ invites: invitesToBeRefreshed })
      .then(() => {
        getAllPendingInvites();
        setSelectedPendingInvites([]);
        toast.success('Invitations refreshed.');
      })
      .catch(() => {
        toast.success('Invitations can not be refreshed.');
      });
  };
  const searchInvites = (value) => {
    if (!value) {
      setFilteredPendingInvites(pendingInvites);
    } else {
      setFilteredPendingInvites(
        pendingInvites?.filter(({ email }) =>
          email?.toLowerCase()?.includes(value?.toLowerCase())
        )
      );
    }
  };
  const loadMore = () => setShownResults(shownResults + 10);

  return (
    <>
      <Heading text='PENDING INVITATIONS' />
      {didDataLoad ? (
        <div className='jobs-card'>
          <div className='jobs-card-header d-flex jc-sp ai-center'>
            <div className='d-flex'>
              <div
                className={`d-flex ai-center action-card ${
                  selectedPendingInvites?.length === 0
                    ? 'disabled-action-card'
                    : ''
                } cursor-pointer`}
                onClick={refreshInvites}
              >
                <img
                  src={
                    jobType === '/admin/console/pending-invites' &&
                    selectedPendingInvites?.length === 0
                      ? refreshIconGrey
                      : jobType === '/admin/console/pending-invites' &&
                        selectedPendingInvites?.length !== 0
                      ? refreshIcon
                      : ''
                  }
                  alt='archive'
                  className='action-bar-icon'
                />
                Re-invite Selected
              </div>
              <div
                className={`d-flex ai-center action-card ${
                  selectedPendingInvites?.length === 0
                    ? 'disabled-action-card'
                    : ''
                } cursor-pointer`}
                onClick={deleteInvites}
              >
                <img
                  src={
                    jobType === '/admin/console/pending-invites' &&
                    selectedPendingInvites?.length === 0
                      ? binIconGrey
                      : jobType === '/admin/console/pending-invites' &&
                        selectedPendingInvites?.length !== 0
                      ? binIcon
                      : ''
                  }
                  alt='archive'
                  className='action-bar-icon'
                />
                Delete Selected
              </div>

              <InvitePopover>
                <div className='d-flex ai-center action-card invite-card cursor-pointer'>
                  <img
                    src={inviteIcon}
                    alt='archive'
                    className='action-bar-icon'
                  />
                  Invite
                </div>
              </InvitePopover>
            </div>
            <div>
              <div className='d-flex ai-center action-card right'>
                <div className='ui search searchbar-container'>
                  <div className='ui icon input'>
                    <input
                      className='prompt'
                      type='text'
                      placeholder='Search Invites'
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e?.target?.value);
                        searchInvites(e?.target?.value);
                      }}
                    />
                    <i className='search icon' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {pendingInvites?.length !== 0 ? (
            <>
              {filteredPendingInvites?.length !== 0 ? (
                <>
                  <div className='jobs-card-table pending-invites'>
                    <table
                      border={1}
                      cellPadding={20}
                      cellSpacing={10}
                      className='jobs-table'
                    >
                      <thead>
                        <th>
                          <label className='custom-checkbox-container'>
                            <input
                              type='checkbox'
                              className='custom-checkbox'
                              onClick={toggleAllSelection}
                              checked={
                                pendingInvites?.length > 0 &&
                                pendingInvites?.length ===
                                  selectedPendingInvites?.length
                              }
                            />
                            <span className='custom-checkmark'></span>
                          </label>
                        </th>
                        <th>EMAIL</th>
                        <th>INVITE SENT</th>
                      </thead>
                      <tbody>
                        {filteredPendingInvites?.map(
                          (
                            { email, _id, createdAt },
                            index
                            // eslint-disable-next-line array-callback-return
                          ) => {
                            if (index < shownResults) {
                              return (
                                <tr key={_id}>
                                  <td>
                                    <label className='custom-checkbox-container'>
                                      <input
                                        type='checkbox'
                                        className='custom-checkbox'
                                        onClick={() => toggleSelection(_id)}
                                        checked={selectedPendingInvites?.includes(
                                          _id
                                        )}
                                      />
                                      <span className='custom-checkmark' />
                                    </label>
                                  </td>
                                  <td>{email}</td>
                                  <td
                                    onClick={() => toggleCreationTime(_id)}
                                    className='created-at-td'
                                  >
                                    {toggledCreationTimes?.includes(_id)
                                      ? moment(createdAt).format(
                                          'MM/DD/YY - hh:mm a'
                                        )
                                      : moment(createdAt).fromNow()}
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='jobs-card-footer'>
                    {shownResults < filteredPendingInvites?.length && (
                      <span className='load-more-btn' onClick={loadMore}>
                        <img src={loadMoreIconGrey} alt='load-more' />
                        Load more...
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <EmptyTable text='No match found' />
              )}
            </>
          ) : (
            <EmptyTable text={`No pending invites`} />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default PendingInvites;
