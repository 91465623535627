import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
import Heading from '../../Common/Heading/Heading';
import MasterPasswordCard from './MasterPassword/MasterPasswordCard';

const Settings = () => {
  const { push } = useHistory();
  useEffect(() => {
    push(`${ADMIN_CONSOLE_PATH}/settings`);
  }, [push]);
  return (
    <>
      <Heading text='SETTINGS' />
      <MasterPasswordCard />
    </>
  );
};

export default Settings;
