import {
  SET_COMPETITOR_DOMAIN,
  SET_COMPETITOR_DOMAINS_LIST,
  SET_DEFAULT_COMPETITOR_DOMAINS,
  SET_EDITED_COMPETITOR_DOMAIN,
  SET_EDITING_DOMAIN,
  SET_IS_ADDING_DOMAIN,
} from '../action-types/competitor-domain';

export const setIsAddingDomain = (flag) => ({
  type: SET_IS_ADDING_DOMAIN,
  payload: flag,
});
export const setEditingDomin = (domain) => ({
  type: SET_EDITING_DOMAIN,
  payload: domain,
});
export const setCompetitorDomainsList = (domains) => ({
  type: SET_COMPETITOR_DOMAINS_LIST,
  payload: domains,
});
export const setCompetitorDomain = (domain) => ({
  type: SET_COMPETITOR_DOMAIN,
  payload: domain,
});
export const setEditedCompetitorDomain = (domain) => ({
  type: SET_EDITED_COMPETITOR_DOMAIN,
  payload: domain,
});
export const setDefaultCompetitorDomains = () => ({
  type: SET_DEFAULT_COMPETITOR_DOMAINS,
});
