import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PUBLIC_URL } from '../../../../constants/environment-variables';
import {
  ACTIVE_JOBS_PATH,
  ARCHIVED_JOBS_PATH,
} from '../../../../constants/paths';
import { setJobType } from '../../../../redux/actions/job-details';
import { setSelectedAdminScreen } from '../../../../redux/actions/sidebar';
import './DropdownLink.scss';
const DropdownLink = ({
  icon,
  label,
  isSideBarOpen,
  setIsSideBarOpen,
  options,
  isDropdownExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const selectedAdminScreen = useSelector(
    (state) => state?.sideBar?.selectedAdminScreen
  );
  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    setIsExpanded(isDropdownExpanded());
    dispatch(
      setSelectedAdminScreen(
        window.location.pathname?.includes('active')
          ? ACTIVE_JOBS_PATH
          : window.location.pathname?.includes('archived')
          ? ARCHIVED_JOBS_PATH
          : window.location.pathname
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);
  const handleIconClick = () => {
    setIsSideBarOpen((prevState) => !prevState.isSideBarOpen);
  };
  return (
    <Accordion
      className='console-dropdown'
      expanded={isExpanded}
      onChange={() =>
        setIsExpanded((previouslyExoanded) => !previouslyExoanded)
      }
    >
      <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
        <div
          className={`d-flex  ${
            (label === 'Reports' &&
              window.location.pathname?.includes('jobs')) ||
            (label === 'Console' && window.location.pathname?.includes('admin'))
              ? ''
              : 'non-'
          }active-path side-link`}
          onClick={handleIconClick}
        >
          <div className='side-link-icon-block'>
            <img
              src={icon}
              alt={label}
              className={`side-link-icon ${
                label === 'FETCHER & GROUPER' ? 'big-icon' : ''
              }`}
            />
          </div>
          {isSideBarOpen && <div className='side-link-label'>{label}</div>}
        </div>
      </AccordionSummary>
      {isSideBarOpen && (
        <AccordionDetails className='dropdown-items'>
          {options?.map((option) => (
            <div
              key={option.path}
              className={`non-active-path dropdown-links ${
                selectedAdminScreen !== option?.path
                  ? 'non-selected-screen'
                  : 'selected-screen'
              }`}
              onClick={() => {
                dispatch(setSelectedAdminScreen(option?.path));
                dispatch(setJobType(option?.path));
                push(`${PUBLIC_URL}${option?.path}`);
              }}
            >
              <div>{option?.label}</div>
            </div>
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default DropdownLink;
