import React, { useState } from 'react';
import { runFetcher } from '../../../utils/api';
import './SerpFetcher.scss';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import InputText from '../../Common/InputText/InputText';
import InputSelect from '../../Common/InputSelect/InputSelect';
import CompetitorDomain from '../../Common/CompetitorDomains/CompetitorDomain';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompetitorDomain,
  setCompetitorDomainsList,
  setIsAddingDomain,
} from '../../../redux/actions/competitor-domain';
import {
  setReportTitle,
  setTargetDomain,
  setSearchEngine,
  setLocation,
  setDocumentFile,
  setIgnoreSpecialCharacters,
  setisStartJobButtonClicked,
  setSendingFileProgress,
} from '../../../redux/actions/job-fields';
import { ACTIVE_JOBS_PATH } from '../../../constants/paths';
import Heading from '../../Common/Heading/Heading';
import { setJobType } from '../../../redux/actions/job-details';
import CustomToggleButton from '../../Common/CustomToggleButton/CustomToggleButton';
import DropFile from '../../Common/DropFile/DropFile';
import LocationSelect from '../../Common/LocationSelect/LocationSelect';

const SerpFetcher = () => {
  const competitorDomainsList = useSelector(
    (state) => state?.competitorDomains?.competitorDomainsList
  );
  const isAddingDomain = useSelector(
    (state) => state?.competitorDomains?.isAddingDomain
  );
  const competitorDomain = useSelector(
    (state) => state?.competitorDomains?.competitorDomain
  );
  const editedCompetitorDomain = useSelector(
    (state) => state?.competitorDomains?.editedCompetitorDomain
  );

  const {
    reportTitle,
    targetDomain,
    searchEngine,
    country,
    location,
    language,
    documentFile,
    ignoreSpecialCharacters,
    sendingFileProgress,
    isStartJobButtonClicked,
  } = useSelector((state) => state?.jobFields);

  const dispatch = useDispatch();
  const history = useHistory();

  const [isJobPreparedToStart, setIsJobPreparedToStart] = useState(false);

  const getSerps = () => {
    dispatch(setisStartJobButtonClicked(true));
    if (
      reportTitle.trim() &&
      targetDomain.trim() &&
      searchEngine &&
      country &&
      language &&
      documentFile &&
      location &&
      location !== 'None' &&
      !competitorDomain &&
      !editedCompetitorDomain
    ) {
      setIsJobPreparedToStart(true);
      const formData = new FormData();
      const tempObject = {
        report_title: reportTitle,
        target_domain: targetDomain,
        competitor_domains: competitorDomainsList?.map((dom) => dom.text),
        search_engine: searchEngine,
        location: location?.location,
        language,
        ignore_special_characters: ignoreSpecialCharacters,
        gl: location?.country_code.toLowerCase() ?? 'us',
      };
      const request = JSON.stringify(tempObject);
      formData.append('request', request);
      formData.append('file', documentFile);
      runFetcher(formData, (progress) =>
        dispatch(setSendingFileProgress(progress))
      )
        .then(() => {
          setisStartJobButtonClicked(true);
          setIsJobPreparedToStart(false);
          dispatch(setSendingFileProgress(null));
          toast.success('Fetcher job has started.');
          dispatch(setJobType(ACTIVE_JOBS_PATH));
          history.push(ACTIVE_JOBS_PATH);
        })
        .catch((error) => {
          setisStartJobButtonClicked(true);
          setIsJobPreparedToStart(false);
          toast.error(error?.response?.data?.message);
          dispatch(setSendingFileProgress(null));
        });
    }
  };

  const addDomain = () => {
    if (competitorDomainsList?.length < 10 && competitorDomain) {
      dispatch(
        setCompetitorDomainsList([
          ...competitorDomainsList,
          {
            text: competitorDomain,
            id: Date.now(),
          },
        ])
      );
      dispatch(setCompetitorDomain(''));
      setisStartJobButtonClicked(false);
    }
  };
  const isDisabled =
    isAddingDomain !== false && competitorDomainsList?.length === 10;
  return (
    <>
      <Heading text='SERP Fetcher' />
      <div className='inputs-form'>
        <div className='main-description'>
          <div>Using the Fetcher</div>
          <div>
            Used to extract SERP data for the set of keywords you’ve provided.
          </div>
        </div>
        <InputText
          label='Report Title'
          state={reportTitle}
          setState={(e) => dispatch(setReportTitle(e?.target?.value))}
          stylingClass='report-title-input'
        />

        <div className='domains-container'>
          <InputText
            label='Target Domain'
            state={targetDomain}
            setState={(e) => dispatch(setTargetDomain(e?.target?.value))}
            stylingClass='target-domain-input'
          />
          <InputText
            label='Competitor Domains'
            state={competitorDomain}
            setState={(e) => dispatch(setCompetitorDomain(e?.target?.value))}
            stylingClass='competitor-domains-input'
            onEnterPress={addDomain}
            disabled={isDisabled}
            onFocus={() => dispatch(setIsAddingDomain(true))}
          >
            {isAddingDomain ? (
              <img
                alt='add-domain'
                src={
                  isDisabled
                    ? require('../../../assets/img/disabled-add-domain-icon.svg')
                        .default
                    : require('../../../assets/img/add-domain-icon.svg').default
                }
                className={`${isDisabled ? 'disabled' : ''} add-domain-icon`}
                onClick={addDomain}
              />
            ) : (
              <img
                alt='manage-domains-icon'
                src={
                  require('../../../assets/img/clarity-network-settings-line.svg')
                    .default
                }
                className='manage-domains-icon'
              />
            )}
          </InputText>
        </div>
        {competitorDomainsList?.length > 0 && isAddingDomain && (
          <div className='d-flex jc-sp ai-center'>
            <div className='w-49' />
            <div className='w-49 competitor-card'>
              <div className='tags-card'>
                {competitorDomainsList?.map(({ id, text }) => (
                  <CompetitorDomain key={id} domain={{ id, text }} />
                ))}
              </div>
              <div className='tags-card-footer'>
                <div
                  className='footer-close-button'
                  onClick={() => {
                    dispatch(setIsAddingDomain(false));
                    dispatch(setCompetitorDomain(''));
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='search-engine-container'>
          <div className='sub-heading-text'>search engine settings</div>
          <div className='input-selects-container'>
            <InputSelect
              label='Search Engine'
              options={['Google', 'Bing', 'Yandex']}
              state={searchEngine}
              setState={(value) => dispatch(setSearchEngine(value))}
            />
            <div className='input-select-container'>
              <label className='input-select-label'>Location</label>
              <LocationSelect
                stylingClass={`input-select dropdown ${
                  isStartJobButtonClicked && location === 'None'
                    ? 'red-border'
                    : ''
                }`}
                setLocation={(selectedLocation) => {
                  dispatch(setLocation(selectedLocation));
                }}
              />
            </div>
          </div>
        </div>
        <div className='file-upload-div'>
          <label className='sub-heading-text'>Input File</label>
          <div className='file-upload-container'>
            <span className='file-upload-text'>
              Load your Ahrefs export from keywords explorer below.
            </span>
            <DropFile
              setDocumentFile={(file) => dispatch(setDocumentFile(file))}
              fileName={documentFile?.name}
            />
          </div>
        </div>
        <CustomToggleButton
          label='Ignore special characters'
          stateValue={ignoreSpecialCharacters}
          stateAction={setIgnoreSpecialCharacters}
        />

        <div className='button-container'>
          <button
            className={`custom-button ${
              isJobPreparedToStart && sendingFileProgress === null
                ? 'loader-button'
                : ''
            }`}
            onClick={!isJobPreparedToStart ? getSerps : () => ''}
          >
            Get Serps
          </button>
        </div>
      </div>
    </>
  );
};

export default SerpFetcher;
