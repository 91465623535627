import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  setActiveJobs,
  setArchivedJobs,
  setDidDataLoad,
} from '../../redux/actions/jobs';
import { setJobType } from '../../redux/actions/job-details';
import {
  areYouAdmin,
  getActiveJobs,
  getArchivedJobs,
  getUserJobs,
} from '../../utils/api';
import Jobs from './Jobs';
import { ACTIVE_JOBS_PATH, ADMIN_CONSOLE_PATH } from '../../constants/paths';
import { setSelectedAdminScreen } from '../../redux/actions/sidebar';
import { setIsAdmin } from '../../redux/actions/auth';
import { toast } from 'react-toastify';
import { getAppUser } from '../../utils/helpers';

const JobSelector = ({ jobType }) => {
  const activeJobs = useSelector((state) => state?.jobs?.activeJobs);
  const archivedJobs = useSelector((state) => state?.jobs?.archivedJobs);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  const dispatch = useDispatch();
  const { userId } = useParams();
  const { push } = useHistory();
  useEffect(() => {
    if (getAppUser() && window.location.pathname === '/') {
      push(ACTIVE_JOBS_PATH);
      dispatch(setJobType(ACTIVE_JOBS_PATH));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    areYouAdmin()
      .then(({ data }) => dispatch(setIsAdmin(data)))
      .catch(({ data }) => toast.error(data?.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(setActiveJobs([]));
    dispatch(setArchivedJobs([]));
    dispatch(setDidDataLoad(false));
    if (userId && isAdmin) {
      dispatch(setSelectedAdminScreen(ACTIVE_JOBS_PATH));
      dispatch(setJobType(`${ADMIN_CONSOLE_PATH}/users`));
      getUserJobs(userId)
        .then(({ data }) => {
          dispatch(setActiveJobs(data));
          dispatch(setDidDataLoad(true));
        })
        .catch(() => {
          dispatch(setActiveJobs([]));
          dispatch(setDidDataLoad(true));
        });
    } else {
      if (jobType === 'active') {
        getActiveJobs()
          .then(({ data }) => {
            dispatch(setActiveJobs(data));
            dispatch(setDidDataLoad(true));
          })
          .catch(() => {
            dispatch(setActiveJobs([]));
            dispatch(setDidDataLoad(true));
          });
      } else if (jobType === 'archived') {
        getArchivedJobs()
          .then(({ data }) => {
            dispatch(setArchivedJobs(data));
            dispatch(setDidDataLoad(true));
          })
          .catch(() => {
            dispatch(setActiveJobs([]));
            dispatch(setDidDataLoad(true));
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, jobType, userId]);

  return (
    <Jobs
      userId={userId}
      jobType={jobType}
      jobs={jobType === 'active' ? activeJobs : archivedJobs}
      setJobs={(data) =>
        jobType === 'active'
          ? dispatch(setActiveJobs(data))
          : dispatch(setArchivedJobs(data))
      }
    />
  );
};

export default JobSelector;
