import {
  SET_COMPETITOR_DOMAIN,
  SET_COMPETITOR_DOMAINS_LIST,
  SET_DEFAULT_COMPETITOR_DOMAINS,
  SET_EDITED_COMPETITOR_DOMAIN,
  SET_EDITING_DOMAIN,
  SET_IS_ADDING_DOMAIN,
} from '../action-types/competitor-domain';

const initialState = {
  isAddingDomain: false,
  editingDomain: null,
  competitorDomainsList: [],
  competitorDomain: '',
  editedCompetitorDomain: null,
};
export const isAddingDomainReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SET_IS_ADDING_DOMAIN: {
      return {
        ...state,
        isAddingDomain: payload,
      };
    }
    case SET_EDITING_DOMAIN: {
      return {
        ...state,
        editingDomain: payload,
      };
    }
    case SET_COMPETITOR_DOMAINS_LIST: {
      return {
        ...state,
        competitorDomainsList: payload,
      };
    }
    case SET_COMPETITOR_DOMAIN: {
      return {
        ...state,
        competitorDomain: payload,
      };
    }
    case SET_EDITED_COMPETITOR_DOMAIN: {
      return {
        ...state,
        editedCompetitorDomain: payload,
      };
    }
    case SET_DEFAULT_COMPETITOR_DOMAINS: {
      return initialState;
    }
    default:
      return state;
  }
};
