import React from 'react';
import './Loading.scss';
const Loading = ({ whichLoader = 'default' }) => (
  <div
    id={`${
      whichLoader === 'login'
        ? 'login-page-loading'
        : whichLoader === 'logs'
        ? 'logsloading'
        : 'page-loading'
    }`}
  >
    <div
      className={`three-balls ${whichLoader === 'login' ? 'pos-usnet' : ''}`}
    >
      <div
        className={`ball ball1 ${
          whichLoader !== 'default' ? 'small-balls' : ''
        }`}
      />
      <div
        className={`ball ball2 ${
          whichLoader !== 'default' ? 'small-balls' : ''
        }`}
      />
      <div
        className={`ball ball3 ${
          whichLoader !== 'default' ? 'small-balls' : ''
        }`}
      />
    </div>
  </div>
);

export default Loading;
