import React from 'react';
import './PulsatingDot.scss';
const PulsatingDot = ({ status }) => (
  <div className='ring-container'>
    {status === 'running' ? <div className='ringring' /> : ''}
    <div
      className={`circle ${
        status === 'running'
          ? 'blue-dot'
          : status === 'completed'
          ? 'green-dot'
          : status === 'failed'
          ? 'red-dot'
          : 'grey-dot'
      }`}
    />
  </div>
);
export default PulsatingDot;
