import React from 'react';
import { ToastContainer } from 'react-toastify';
const Toast = () => (
  <div>
    <ToastContainer
      position='bottom-right'
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </div>
);

export default Toast;
