import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setDefaultCompetitorDomains } from '../../../redux/actions/competitor-domain';
import { setJobType } from '../../../redux/actions/job-details';
import { setDefaultJobFields } from '../../../redux/actions/job-fields';
import { setSelectedAdminScreen } from '../../../redux/actions/sidebar';

const SideLink = ({ label, icon, path, isSideBarOpen, setShow }) => {
  const { push } = useHistory();

  const jobType = useSelector((state) => state?.jobDetails?.jobType);

  const dispatch = useDispatch();
  const setDefaultState = () => {
    dispatch(setDefaultCompetitorDomains());
    dispatch(setDefaultJobFields());
  };

  return (
    <div
      className={`d-flex ${
        !jobType?.includes(path) ? 'non-' : ''
      }active-path side-link`}
      onClick={() => {
        if (label !== 'Logout') {
          push(path);
          dispatch(setJobType(path));
          dispatch(setSelectedAdminScreen(''));
          setDefaultState();
        } else {
          setShow(true);
        }
      }}
    >
      <div className='side-link-icon-block'>
        <img
          src={icon}
          alt={label}
          className={`side-link-icon ${
            label === 'FETCHER & GROUPER' ? 'big-icon' : ''
          }`}
        />
      </div>
      {isSideBarOpen && <div className='side-link-label'>{label}</div>}
    </div>
  );
};

export default SideLink;
