import React from 'react';
import './UserPicLoader.scss';
const UserPicLoader = () => {
  return (
    <div className='user-pic-loader-container'>
      <div className='user-pic-loader' />
    </div>
  );
};

export default UserPicLoader;
