import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  deleteJob,
  archiveJob,
  unArchiveJob,
  deleteMultipleJobs,
  changeJobsStates,
  downloadFileFromCloud,
  areYouAdmin,
} from '../../utils/api';
import './Jobs.scss';
import PulsatingDot from '../Common/PulsatingDot/PulsatingDot';
import Loading from '../Common/Loading/Loading';
import { toast } from 'react-toastify';
import CustomPopover from '../Common/CustomPopover/CustomPopover';
import { useHistory } from 'react-router-dom';
import EmptyTable from '../Common/EmptyTable/EmptyTable';
import { INPUT_SIZE_FROM_NODE, NODE_JOB_STATUS } from '../../constants/socket';
import { Tooltip } from '@material-ui/core';
import Heading from '../Common/Heading/Heading';
import archiveIcon from '../../assets/img/archive-icon.svg';
import unarchiveIcon from '../../assets/img/unarchive-icon.svg';
import archiveIconGrey from '../../assets/img/archive-icon-grey.svg';
import unarchiveIconGrey from '../../assets/img/unarchive-icon-grey.svg';
import binIcon from '../../assets/img/bin-icon.svg';
import binIconGrey from '../../assets/img/bin-icon-grey.svg';
import reportsIcon from '../../assets/img/reports.svg';
import reportsIconGrey from '../../assets/img/reports-grey.svg';
import loadMoreIconGrey from '../../assets/img//load-more-icon-grey.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIVE_JOBS_PATH, ARCHIVED_JOBS_PATH } from '../../constants/paths';
import { setJobType } from '../../redux/actions/job-details';
import useCustomSocket from '../../hooks/useCustomSocket';

const Jobs = ({ userId, jobType, jobs, setJobs }) => {
  const [filteredJobs, setFilteredJobs] = useState(jobs);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [toggledCreationTimes, setToggledCreationTimes] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [shownResults, setShownResults] = useState(10);
  const didDataLoad = useSelector((state) => state?.jobs?.didDataLoad);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const dispatch = useDispatch();
  const { push } = useHistory();
  useEffect(() => {
    setFilteredJobs(jobs);
    setSelectedJobs([]);
    setSearchValue('');
    setShownResults(10);
  }, [jobs]);

  const updateStatus = useCallback(
    ({ jobId, status }) => {
      if (jobId !== undefined && status !== undefined) {
        const newJobs = jobs?.map((job) =>
          job?._id === jobId ? { ...job, status } : job
        );
        setJobs(newJobs);
        setFilteredJobs(newJobs);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobs]
  );
  const socketJobStatus = (message) => {
    if (message.event === NODE_JOB_STATUS) {
      updateStatus(message);
    }
  };
  useCustomSocket(socketJobStatus);

  useEffect(() => {
    areYouAdmin().then(({ data }) => {
      if (!data) {
        const route =
          jobType === 'active' ? ACTIVE_JOBS_PATH : ARCHIVED_JOBS_PATH;
        dispatch(setJobType(route));
        push(route);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  const updateInputSize = useCallback(
    ({ jobId, inputSize }) => {
      const newJobs = jobs?.map((job) =>
        job?._id === jobId ? { ...job, input_size: inputSize } : job
      );
      setJobs(newJobs);
      setFilteredJobs(newJobs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobs]
  );
  const socketInputSize = (message) => {
    if (message.event === INPUT_SIZE_FROM_NODE) {
      updateInputSize(message);
    }
  };
  useCustomSocket(socketInputSize);

  const deleteSelectedJobs = () =>
    deleteMultipleJobs({ jobs: selectedJobs })
      .then((response) => {
        const newJobs = jobs?.filter(
          (job) => !selectedJobs?.includes(job?._id)
        );
        setJobs(newJobs);
        setFilteredJobs(newJobs);
        setSelectedJobs([]);
        setShownResults(10);
        toast.success(response?.data?.message);
      })
      .catch((error) => toast.error(error?.data?.message));

  const deleteSingleJob = (id) =>
    deleteJob(id)
      .then((response) => {
        const newJobs = jobs?.filter((job) => job?._id !== id);
        setJobs(newJobs);
        setFilteredJobs(newJobs);
        setShownResults(10);
        toast.success(response?.data?.message);
      })
      .catch((error) => toast.error(error?.data?.message));

  const archiveSelectedJobs = () =>
    changeJobsStates({ jobs: selectedJobs, jobState: 'archive' })
      .then((response) => {
        const newJobs = jobs?.filter(
          (job) => !selectedJobs?.includes(job?._id)
        );
        setJobs(newJobs);
        setFilteredJobs(newJobs);
        setSelectedJobs([]);
        setShownResults(10);
        toast.success(response?.data?.message);
      })
      .catch((error) => toast.error(error?.data?.message));

  const archiveSingleJob = (id) =>
    archiveJob(id)
      .then((response) => {
        const newJobs = jobs?.filter((job) => job?._id !== id);
        setJobs(newJobs);
        setFilteredJobs(newJobs);
        setShownResults(10);
        toast.success(response?.data?.message);
      })
      .catch((error) => toast.error(error?.data?.message));

  const unarchiveSelectedJobs = () =>
    changeJobsStates({ jobs: selectedJobs, jobState: 'unarchive' })
      .then((response) => {
        const newJobs = jobs?.filter(
          (job) => !selectedJobs?.includes(job?._id)
        );
        setJobs(newJobs);
        setFilteredJobs(newJobs);
        setSelectedJobs([]);
        setShownResults(10);
        toast.success(response?.data?.message);
      })
      .catch((error) => toast.error(error?.data?.message));
  const unArchiveSingleJob = (id) =>
    unArchiveJob(id)
      .then((response) => {
        const newJobs = jobs?.filter((job) => job?._id !== id);
        setJobs(newJobs);
        setFilteredJobs(newJobs);
        setShownResults(10);
        toast.success(response?.data?.message);
      })
      .catch((error) => toast.error(error?.data?.message));

  const toggleSelection = (jobId) => {
    if (selectedJobs?.includes(jobId)) {
      const updatedJobs = selectedJobs?.filter((id) => id !== jobId);
      setSelectedJobs(updatedJobs);
    } else {
      const updatedJobs = [...selectedJobs, jobId];
      setSelectedJobs(updatedJobs);
    }
  };
  const toggleAllSelection = () => {
    const allJobs = jobs?.map(({ _id }) => _id);
    if (selectedJobs?.length < allJobs?.length) {
      setSelectedJobs(allJobs);
    } else {
      setSelectedJobs([]);
    }
  };
  const searchJobs = (value) => {
    if (!value) {
      setFilteredJobs(jobs);
    } else {
      setFilteredJobs(
        jobs?.filter(({ report_title }) =>
          report_title?.toLowerCase()?.includes(value?.toLowerCase())
        )
      );
    }
  };
  const toggleCreationTime = (id) => {
    if (toggledCreationTimes?.includes(id)) {
      setToggledCreationTimes(
        toggledCreationTimes?.filter((jobId) => jobId !== id)
      );
    } else {
      setToggledCreationTimes([...toggledCreationTimes, id]);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, []);

  const downloadMultipleFiles = () => {
    // eslint-disable-next-line array-callback-return
    selectedJobs?.map((id) => {
      const status = jobs?.filter(({ _id }) => _id === id)?.[0]?.status;
      if (status === 'completed') {
        downloadFileFromCloud({
          id,
          reportType: 'final',
        }).then((response) => {
          const filePath = response?.data?.url;
          const a = document.createElement('A');
          a.href = filePath;
          a.target = '_blank';
          a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      }
    });
  };

  const loadMore = () => setShownResults(shownResults + 10);
  return (
    <>
      <Heading
        text={`${
          userId ? 'All' : jobType === 'active' ? 'Active' : 'Archived'
        } Jobs`}
      />
      {didDataLoad ? (
        <div className='jobs-card'>
          {!userId && (
            <div className='jobs-card-header d-flex jc-sp ai-center'>
              <div className='d-flex'>
                <div
                  className={`d-flex ai-center action-card ${
                    selectedJobs?.length === 0 ? 'disabled-action-card' : ''
                  } cursor-pointer`}
                  onClick={
                    jobType === 'archived'
                      ? unarchiveSelectedJobs
                      : archiveSelectedJobs
                  }
                >
                  <img
                    src={
                      jobType === 'archived' && selectedJobs?.length === 0
                        ? unarchiveIconGrey
                        : jobType === 'archived' && selectedJobs?.length !== 0
                        ? unarchiveIcon
                        : jobType === 'active' && selectedJobs?.length === 0
                        ? archiveIconGrey
                        : archiveIcon
                    }
                    alt='archive'
                    className='action-bar-icon'
                  />
                  {jobType === 'archived' ? 'Unarchive' : 'Archive'} Selected
                </div>

                <div
                  className={`d-flex ai-center action-card ${
                    selectedJobs?.length === 0 ? 'disabled-action-card' : ''
                  }  cursor-pointer`}
                  onClick={downloadMultipleFiles}
                >
                  <img
                    src={
                      selectedJobs?.length === 0 ? reportsIconGrey : reportsIcon
                    }
                    alt='download'
                    className='action-bar-icon'
                  />
                  Download Reports
                </div>
                {jobType === 'archived' && (
                  <CustomPopover confirmButtonFunction={deleteSelectedJobs}>
                    <div
                      className={`d-flex ai-center action-card ${
                        selectedJobs?.length === 0 ? 'disabled-action-card' : ''
                      } cursor-pointer`}
                    >
                      <img
                        src={selectedJobs?.length === 0 ? binIconGrey : binIcon}
                        alt='archive'
                        className='action-bar-icon'
                      />
                      Delete Selected
                    </div>
                  </CustomPopover>
                )}
              </div>
              <div>
                <div className='d-flex ai-center action-card right'>
                  <div className='ui search searchbar-container'>
                    <div className='ui icon input'>
                      <input
                        className='prompt'
                        type='text'
                        placeholder='Search Jobs'
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e?.target?.value);
                          searchJobs(e?.target?.value);
                        }}
                      />
                      <i className='search icon' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {jobs?.length !== 0 ? (
            <>
              {filteredJobs?.length !== 0 ? (
                <>
                  <div className='jobs-card-table'>
                    <table
                      border={1}
                      cellPadding={20}
                      cellSpacing={10}
                      className={`jobs-table ${userId && 'jobs-table-view'}`}
                    >
                      <thead>
                        {!userId && (
                          <th>
                            <label className='custom-checkbox-container'>
                              <input
                                type='checkbox'
                                className='custom-checkbox'
                                onClick={toggleAllSelection}
                                checked={
                                  jobs?.length > 0 &&
                                  jobs?.length === selectedJobs?.length
                                }
                              />
                              <span className='custom-checkmark'></span>
                            </label>
                          </th>
                        )}
                        <th>TITLE</th>
                        <th>STATUS</th>
                        <th>TYPE</th>
                        <th>SIZE</th>
                        <th>CREATED</th>
                        <th />
                        {!userId && <th />}
                        {jobType === 'archived' && <th />}
                      </thead>
                      <tbody>
                        {filteredJobs?.map(
                          (
                            {
                              input_size,
                              _id,
                              report_title,
                              status,
                              type,
                              createdAt,
                              is_archived,
                            },
                            index
                            // eslint-disable-next-line array-callback-return
                          ) => {
                            if (index < shownResults) {
                              return (
                                <tr key={_id}>
                                  {!userId && (
                                    <td>
                                      <label className='custom-checkbox-container'>
                                        <input
                                          type='checkbox'
                                          className='custom-checkbox'
                                          onClick={() => toggleSelection(_id)}
                                          checked={selectedJobs?.includes(_id)}
                                        />
                                        <span className='custom-checkmark' />
                                      </label>
                                    </td>
                                  )}
                                  <td>{report_title}</td>
                                  <td>
                                    <div className='jobs-status'>
                                      <PulsatingDot status={status} />
                                      {status}
                                    </div>
                                  </td>
                                  <td className='jobs-type'>{type}</td>
                                  <td>{input_size ?? '-'}</td>
                                  <td
                                    onClick={() => toggleCreationTime(_id)}
                                    className='created-at-td'
                                  >
                                    {toggledCreationTimes?.includes(_id)
                                      ? moment(createdAt).format(
                                          'MM/DD/YY - hh:mm a'
                                        )
                                      : moment(createdAt).from(
                                          moment(currentTime)
                                        )}
                                  </td>
                                  <td>
                                    <button
                                      className='custom-button download-btn'
                                      onClick={() => {
                                        if (userId) {
                                          push(
                                            `/jobs/${userId}/${
                                              is_archived
                                                ? 'archived'
                                                : 'active'
                                            }/${type}/${_id}/details`
                                          );
                                        } else {
                                          push(
                                            `/jobs/${
                                              is_archived
                                                ? 'archived'
                                                : 'active'
                                            }/${type}/${_id}/details`
                                          );
                                        }
                                      }}
                                    >
                                      Details
                                    </button>
                                  </td>
                                  {jobType === 'archived' && !userId ? (
                                    <td>
                                      <Tooltip
                                        title='Unarchive'
                                        placement='top'
                                        PopperProps={{
                                          popperOptions: {
                                            modifiers: {
                                              offset: {
                                                enabled: true,
                                                offset: '0px, -6px',
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <img
                                          src={unarchiveIcon}
                                          alt='unarchive'
                                          height='20px'
                                          className='unarchive-icon'
                                          onClick={() =>
                                            unArchiveSingleJob(_id)
                                          }
                                        />
                                      </Tooltip>
                                    </td>
                                  ) : (
                                    ''
                                  )}

                                  {!userId && (
                                    <td>
                                      {jobType === 'active' ? (
                                        <Tooltip
                                          title='Archive'
                                          placement='top'
                                          PopperProps={{
                                            popperOptions: {
                                              modifiers: {
                                                offset: {
                                                  enabled: true,
                                                  offset: '0px, -6px',
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <img
                                            src={archiveIcon}
                                            alt='archive'
                                            height='20px'
                                            className='unarchive-icon'
                                            onClick={() =>
                                              archiveSingleJob(_id)
                                            }
                                          />
                                        </Tooltip>
                                      ) : (
                                        <CustomPopover
                                          confirmButtonFunction={() =>
                                            deleteSingleJob(_id)
                                          }
                                        >
                                          <img
                                            src={binIcon}
                                            alt='delete'
                                            height='20px'
                                            className='unarchive-icon'
                                          />
                                        </CustomPopover>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='jobs-card-footer'>
                    {shownResults < filteredJobs?.length && (
                      <span className='load-more-btn' onClick={loadMore}>
                        <img src={loadMoreIconGrey} alt='load-more' />
                        Load more...
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <EmptyTable text='No match found' />
              )}
            </>
          ) : (
            <EmptyTable text={`No ${jobType} jobs`} />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Jobs;
