import { API_URL } from '../../constants/environment-variables';
import { request } from 'axios';
import {
  ACTIVE_JOBS_PATH,
  ARCHIVED_JOBS_PATH,
  FETCHER_GROUPER_PATH,
  FETCHER_PATH,
  GROUPER_PATH,
  KEYWORD_HUB_CREATOR,
} from '../../constants/paths';
import { getAppUser } from '../helpers';
const getJWT = () => {
  const user = getAppUser();
  if (user) return user['token'];
  return '';
};
const getHeaders = (jwtToken) => ({
  Authorization: 'Bearer ' + jwtToken,
  'Access-Control-Allow-Origin': '*',
});

export const getJob = (jobId) => {
  return request({
    url: `${API_URL}/jobs/${jobId}`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });
};

export const runFetcher = (details, setProgress) =>
  request({
    url: `${API_URL}/runjob${FETCHER_PATH}`,
    method: 'POST',
    data: details,
    headers: getHeaders(getJWT()),
    onUploadProgress: ({ loaded, total }) => {
      const percentCompleted = Math.round((loaded * 100) / total);
      setProgress(percentCompleted);
    },
  });

export const runGrouper = (details, setProgress) =>
  request({
    url: `${API_URL}/runjob${GROUPER_PATH}`,
    method: 'POST',
    data: details,
    headers: getHeaders(getJWT()),
    onUploadProgress: ({ loaded, total }) => {
      const percentCompleted = Math.round((loaded * 100) / total);
      setProgress(percentCompleted);
    },
  });
export const runFetcherGrouper = (details, setProgress) =>
  request({
    url: `${API_URL}/runjob${FETCHER_GROUPER_PATH}`,
    method: 'POST',
    data: details,
    headers: getHeaders(getJWT()),
    onUploadProgress: ({ loaded, total }) => {
      const percentCompleted = Math.round((loaded * 100) / total);
      setProgress(percentCompleted);
    },
  });
export const runHubCreator = (details, setProgress) =>
  request({
    url: `${API_URL}/runjob${KEYWORD_HUB_CREATOR}`,
    method: 'POST',
    data: details,
    headers: getHeaders(getJWT()),
    onUploadProgress: ({ loaded, total }) => {
      const percentCompleted = Math.round((loaded * 100) / total);
      setProgress(percentCompleted);
    },
  });
export const resumeJob = (jobId) =>
  request({
    method: 'PATCH',
    url: `${API_URL}/jobs/${jobId}/resume`,
    headers: getHeaders(getJWT()),
  });
export const getActiveJobs = () =>
  request({
    url: `${API_URL}${ACTIVE_JOBS_PATH}`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });
export const getArchivedJobs = () =>
  request({
    url: `${API_URL}${ARCHIVED_JOBS_PATH}`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });
export const getUserJobs = (userId) =>
  request({
    url: `${API_URL}/jobs/admin/${userId}/active`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });

export const downloadFileFromCloud = ({ id, reportType }) =>
  request({
    url: `${API_URL}/file/report/${reportType}/${id}`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });
export const getJobLogs = (id) =>
  request({
    url: `${API_URL}/jobs/logs/${id}`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });
export const userLogin = ({ username, password }) =>
  request({
    url: `${API_URL}/auth/login`,
    method: 'POST',
    data: { username, password },
  });
export const archiveJob = (id) =>
  request({
    url: `${API_URL}/jobs/${id}/archive`,
    method: 'POST',
    headers: getHeaders(getJWT()),
  });
export const unArchiveJob = (id) =>
  request({
    url: `${API_URL}/jobs/${id}/unarchive`,
    method: 'POST',
    headers: getHeaders(getJWT()),
  });
export const deleteJob = (id) =>
  request({
    url: `${API_URL}/jobs/${id}`,
    method: 'DELETE',
    headers: getHeaders(getJWT()),
  });
export const changeJobsStates = ({ jobs, jobState }) =>
  request({
    url: `${API_URL}/jobs/${jobState}/multiple`,
    method: 'POST',
    headers: getHeaders(getJWT()),
    data: { jobs },
  });
export const deleteMultipleJobs = ({ jobs }) =>
  request({
    url: `${API_URL}/jobs/multiple`,
    method: 'DELETE',
    headers: getHeaders(getJWT()),
    data: { jobs },
  });
export const inviteUser = ({ email }) =>
  request({
    url: `${API_URL}/verification/invite`,
    method: 'POST',
    headers: getHeaders(getJWT()),
    data: { email },
  });
export const verifyToken = ({ token }) =>
  request({
    url: `${API_URL}/verification/token`,
    method: 'POST',
    headers: getHeaders(getJWT()),
    data: { token },
  });
export const registerUser = ({ email, name, password }) =>
  request({
    url: `${API_URL}/auth/register`,
    method: 'POST',
    data: { email, name, password },
  });
export const setupMasterPassword = ({ password }) =>
  request({
    url: `${API_URL}/auth/master`,
    method: 'PATCH',
    data: { password },
    headers: getHeaders(getJWT()),
  });
export const hasUserMasterPassword = () =>
  request({
    url: `${API_URL}/auth/has-master`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });
export const getUsers = () =>
  request({
    url: `${API_URL}/auth/users`,
    headers: getHeaders(getJWT()),
  });
export const getPendingInvitations = () =>
  request({
    url: `${API_URL}/verification/pending`,
    headers: getHeaders(getJWT()),
  });
export const changeUsersActiveStatus = ({ users, action }) =>
  request({
    method: 'PATCH',
    url: `${API_URL}/auth/users/active-status`,
    headers: getHeaders(getJWT()),
    data: { users, action },
  });
export const deletePendingInvites = ({ invites }) =>
  request({
    method: 'DELETE',
    url: `${API_URL}/verification/invite`,
    headers: getHeaders(getJWT()),
    data: { invites },
  });

export const resendPendingInvites = ({ invites }) =>
  request({
    method: 'POST',
    url: `${API_URL}/verification/invite/resend`,
    headers: getHeaders(getJWT()),
    data: { invites },
  });
export const updateAPICredits = ({ userId, apiCredits }) =>
  request({
    method: 'PATCH',
    url: `${API_URL}/auth/user/credits`,
    headers: getHeaders(getJWT()),
    data: { userId, apiCredits },
  });
export const getAPICredits = () =>
  request({
    method: 'GET',
    url: `${API_URL}/auth/user/credits`,
    headers: getHeaders(getJWT()),
  });
export const areYouAdmin = () =>
  request({
    method: 'GET',
    url: `${API_URL}/auth/is-admin`,
    headers: getHeaders(getJWT()),
  });
export const updateUserAvatar = (formData) =>
  request({
    method: 'POST',
    url: `${API_URL}/auth/user/avatar`,
    headers: getHeaders(getJWT()),
    data: formData,
  });

export const getUserAvatar = () =>
  request({
    method: 'GET',
    url: `${API_URL}/auth/user/avatar`,
    headers: getHeaders(getJWT()),
  });
export const getSupportedLocations = (location, limit) =>
  request({
    url: `${API_URL}/locations?location=${location}&limit=${limit}`,
    method: 'GET',
    headers: getHeaders(getJWT()),
  });
