import React, { useCallback, useEffect, useState } from 'react';
import JobDetails from './JobDetails';
import { useParams, useHistory } from 'react-router';
import { getJob } from '../../../utils/api';
import { NODE_JOB_STATUS } from '../../../constants/socket';
import useCustomSocket from '../../../hooks/useCustomSocket';
import { populateJobDetails } from '../../../utils/helpers';
import { setJobType } from '../../../redux/actions/job-details';
import { useDispatch } from 'react-redux';
import { ADMIN_CONSOLE_PATH } from '../../../constants/paths';
import { setSelectedAdminScreen } from '../../../redux/actions/sidebar';
import './Details.scss';

const DetailsModal = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLaoding] = useState(false);
  const [jobStatus, setJobStatus] = useState(null);
  const [jobDetails, setJobDetails] = useState([]);
  const [fetcherPathFromSocket, setFetcherPathFromSocket] = useState('');
  const [fetcherBulkPathFromSocket, setFetcherBulkPathFromSocket] =
    useState('');
  const [grouperPathFromSocket, setGrouperPathFromSocket] = useState('');
  const [hubCreatorPathFromSocket, setHubCreatorPathFromSocket] = useState('');
  const history = useHistory();

  const detailProps = [
    { key: 'status', value: jobStatus },
    { key: 'fetcherPathFromSocket', value: fetcherPathFromSocket },
    {
      key: 'fetcherBulkPathFromSocket',
      value: fetcherBulkPathFromSocket,
    },
    { key: 'grouperPathFromSocket', value: grouperPathFromSocket },
    { key: 'hubCreatorPathFromSocket', value: hubCreatorPathFromSocket },
  ];
  populateJobDetails(detailProps, jobDetails);

  const { _id, status } = jobDetails;
  const params = useParams();
  const { jobId, jobState, userId } = params;
  useEffect(() => {
    setIsLaoding(true);
    getJob(jobId)
      .then(({ data }) => {
        setJobDetails(data);
        setJobStatus(data?.status);
        setIsLaoding(false);
      })
      .catch(() => {
        setIsLaoding(false);
      });
  }, [jobId, status]);
  const updateStatus = useCallback(
    ({ jobId, status, fetcherPath, fetcherBulkPath, grouperPath, hubPath }) => {
      if (_id === jobId) {
        setJobStatus(status);
        if (fetcherPath) {
          setFetcherPathFromSocket(fetcherPath);
        }
        if (fetcherBulkPath) {
          setFetcherBulkPathFromSocket(fetcherBulkPath);
        }
        if (grouperPath) {
          setGrouperPathFromSocket(grouperPath);
        }
        if (hubPath) {
          setHubCreatorPathFromSocket(hubPath);
        }
      }
    },
    [_id]
  );

  const socketJobStatus = (message) => {
    if (message.event === NODE_JOB_STATUS) {
      updateStatus(message);
    }
  };
  useCustomSocket(socketJobStatus);

  useEffect(() => {
    if (userId) {
      dispatch(setJobType(`${ADMIN_CONSOLE_PATH}/users`));
      dispatch(setSelectedAdminScreen('users'));
    } else {
      dispatch(setJobType(`/jobs/${jobState}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <>
      <div className='breadcrumb-container'>
        <span
          onClick={() => history.goBack()}
          className='cursor-pointer breadcrumb-link'
        >
          {userId
            ? 'All Jobs'
            : !jobDetails?.is_archived
            ? 'Active Jobs'
            : 'Archived Jobs'}
        </span>
        <span className='breadcrumb-separator'>{'>'}</span>{' '}
        {jobDetails?.report_title}
      </div>
      <JobDetails
        details={detailProps}
        isLoading={isLoading}
        jobState={jobState}
      />
    </>
  );
};

export default DetailsModal;
