import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTIVE_JOBS_PATH,
  ADMIN_CONSOLE_PATH,
  ARCHIVED_JOBS_PATH,
  FETCHER_GROUPER_PATH,
  FETCHER_PATH,
  GROUPER_PATH,
  // KEYWORD_HUB_CREATOR,
  // ADMIN_CONSOLE_PATH,
} from '../../../constants/paths';
import { setJobType } from '../../../redux/actions/job-details';
import './Sidebar.scss';
import SideLink from './SideLink';
import noUserPic from '../../../assets/img/sidebar-icons/no-user-pic.svg';
import expandArrowsIcon from '../../../assets/img/sidebar-icons/expand-arrows-icon.svg';
import collapseArrowsIcon from '../../../assets/img/sidebar-icons/collapse-arrows-icon.svg';
import fetcherIcon from '../../../assets/img/sidebar-icons/fetcher-icon.svg';
import grouperIcon from '../../../assets/img/sidebar-icons/grouper-icon.svg';
import combinedIcon from '../../../assets/img/sidebar-icons/combined-icon.svg';
// import clusterIcon from '../../../assets/img/side-bar-icons/cluster-icon.svg';
import reportsIcon from '../../../assets/img/sidebar-icons/reports-icon.svg';
import consoleIcon from '../../../assets/img/sidebar-icons/console-icon.svg';
// import selectedClusterIcon from '../../../assets/img/side-bar-icons/selected-cluster-icon.svg';
import { setIsUserLoggedIn } from '../../../redux/actions/auth';
import { useHistory } from 'react-router';
import LogoutModal from '../LogoutModal/LogoutModal';
import DropdownLink from './DropdownLink/DropdownLink';
import {
  getAppUser,
  getSideBarStatus,
  isLinksDropdownExpanded,
  removeAppUser,
  setSideBarOpenStatus,
} from '../../../utils/helpers';
import AvatarEditor from '../AvatarEditor/AvatarEditor';
import {
  setIsUserAvatarHovered,
  setUserAvatar,
} from '../../../redux/actions/users';
import UserPicLoader from '../UserPicLoader/UserPicLoader';

const REPORT_OPTIONS = [
  { label: 'Active Jobs', path: ACTIVE_JOBS_PATH },
  { label: 'Archived Jobs', path: ARCHIVED_JOBS_PATH },
];
const CONSOLE_OPTIONS = [
  { label: 'Users', path: `${ADMIN_CONSOLE_PATH}/users` },
  {
    label: 'Pending Invites',
    path: `${ADMIN_CONSOLE_PATH}/pending-invites`,
  },
  {
    label: 'Settings',
    path: `${ADMIN_CONSOLE_PATH}/settings`,
  },
];
const Sidebar = ({ isSideBarOpen, setIsSideBarOpen }) => {
  const { push } = useHistory();
  const [show, setShow] = useState(false);

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const userAvatar = useSelector((state) => state?.users?.userAvatar);
  const isUserAvatarLoading = useSelector(
    (state) => state?.users?.isUserAvatarLoading
  );

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const onLogout = () => {
    handleClose();
    removeAppUser();
    dispatch(setIsUserLoggedIn(false));
    push('/login');
  };
  useEffect(() => {
    dispatch(setJobType(window?.location?.pathname));
    setIsSideBarOpen(getSideBarStatus() === 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsSideBarOpen]);
  useEffect(
    () => {
      dispatch(setUserAvatar());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className={`side-bar-container side-bar-flex ${
        isSideBarOpen ? 'side-bar-uncollapsed' : 'side-bar-collapsed'
      }`}
    >
      <div>
        <div className='side-bar-header'>
          <div
            className={`d-flex jc-sp ai-center height ${
              isSideBarOpen ? 'show' : 'hide'
            }-side-bar-header`}
          >
            <div
              className='d-flex jc-sp ai-center'
              style={{ position: 'relative' }}
            >
              {isUserAvatarLoading ? (
                <UserPicLoader />
              ) : (
                <img
                  onMouseEnter={() => dispatch(setIsUserAvatarHovered(true))}
                  src={userAvatar ? userAvatar : noUserPic}
                  alt='user'
                  loading='lazy'
                  className='user-pic'
                />
              )}
              <AvatarEditor />
              <div className='user-info'>
                <div className='welcome-back-text'>Welcome back,</div>
                <div className='user-name-text'>{getAppUser()?.name}</div>
              </div>
            </div>
            {isSideBarOpen && (
              <div
                className='user-settings-icon-container'
                onClick={() => {
                  setIsSideBarOpen(!isSideBarOpen);
                  setSideBarOpenStatus(!isSideBarOpen);
                }}
              >
                <div className='user-settings-icon'>
                  <img src={collapseArrowsIcon} alt='list' loading='lazy' />
                </div>
              </div>
            )}
          </div>
          {!isSideBarOpen && (
            <div
              className='collapsed-settings-icon user-settings-icon-container'
              onClick={() => {
                setIsSideBarOpen(!isSideBarOpen);
                setSideBarOpenStatus(!isSideBarOpen);
              }}
            >
              <div className='user-settings-icon'>
                <img src={expandArrowsIcon} alt='list' loading='lazy' />
              </div>
            </div>
          )}
        </div>

        <div className='side-links-container'>
          <SideLink
            icon={fetcherIcon}
            label='Serp Fetcher'
            path={FETCHER_PATH}
            isSideBarOpen={isSideBarOpen}
          />
          <SideLink
            icon={grouperIcon}
            label='Keyword Grouper'
            path={GROUPER_PATH}
            isSideBarOpen={isSideBarOpen}
          />
          <SideLink
            icon={combinedIcon}
            label={`Fetcher & Grouper`}
            path={FETCHER_GROUPER_PATH}
            isSideBarOpen={isSideBarOpen}
          />
          {/* <SideLink
            icon={
              jobType === KEYWORD_HUB_CREATOR
                ? selectedClusterIcon
                : clusterIcon
            }
            label={`HUB CREATOR`}
            path={KEYWORD_HUB_CREATOR}
            isSideBarOpen={isSideBarOpen}
          /> */}
          <DropdownLink
            icon={reportsIcon}
            label='Reports'
            isSideBarOpen={isSideBarOpen}
            setIsSideBarOpen={setIsSideBarOpen}
            options={REPORT_OPTIONS}
            isDropdownExpanded={() => isLinksDropdownExpanded(REPORT_OPTIONS)}
          />

          <div>
            {isAdmin && (
              <DropdownLink
                icon={consoleIcon}
                label='Console'
                isSideBarOpen={isSideBarOpen}
                setIsSideBarOpen={setIsSideBarOpen}
                options={CONSOLE_OPTIONS}
                isDropdownExpanded={() =>
                  isLinksDropdownExpanded(CONSOLE_OPTIONS)
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className='logout-container button'>
        <SideLink
          icon={
            require('../../../assets/img/sidebar-icons/logout-icon.svg').default
          }
          label='Logout'
          // path={'/login'}
          isSideBarOpen={isSideBarOpen}
          setShow={setShow}
        />
      </div>

      <LogoutModal show={show} handleClose={handleClose} onLogout={onLogout} />
    </div>
  );
};

export default Sidebar;
