import { SET_SELECTED_ADMIN_SCREEN } from '../action-types/sidebar';

const initialState = {
  selectedAdminScreen: '',
};
export const sideBarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ADMIN_SCREEN: {
      return {
        ...state,
        selectedAdminScreen: payload,
      };
    }

    default: {
      return state;
    }
  }
};
