import React, { useState } from 'react';
import { Form, Card, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router';
import agLogo from '../../assets/img/ag-logo.svg';
import { userLogin } from '../../utils/api';
import Loading from '../Common/Loading/Loading';
import './styles.scss';
import logoShape from '../../assets/img/logo-shape.svg';
import { useDispatch } from 'react-redux';
import { setJobType } from '../../redux/actions/job-details';
import { ACTIVE_JOBS_PATH } from '../../constants/paths';
import { setIsUserLoggedIn } from '../../redux/actions/auth';
import InputText from '../Common/InputText/InputText';
import { setAppUser, setSideBarOpenStatus } from '../../utils/helpers';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [validationError, setValidationError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const onLogin = () => {
    if (!username && !password) {
      setValidationError('Please enter credentials.');
    } else if (!username && password) {
      setValidationError('Please fill username field.');
    } else if (username && !password) {
      setValidationError('Please fill password field.');
    } else if (username && password) {
      setIsLoginInProgress(true);
      setValidationError('');
      userLogin({ username, password })
        .then(
          ({
            data: {
              token,
              user: { userId, name, role },
            },
          }) => {
            setSideBarOpenStatus(true);
            setAppUser({ userId, name, token, role });

            dispatch(setIsUserLoggedIn(true));
            dispatch(setJobType(ACTIVE_JOBS_PATH));
            history.push(ACTIVE_JOBS_PATH);
          }
        )
        .catch(() => {
          setIsLoginInProgress(false);
          setValidationError('Invalid credentials.');
        });
    }
  };

  return (
    <div className='login-container'>
      <img
        src={logoShape}
        alt='Ardent Growth Logo'
        loading='lazy'
        className='logo-shape'
      />
      <Card className='login-card'>
        <Card.Header className=' login-card-header'>
          <div>Sign In</div>
          <div>
            <img src={agLogo} width='30' height='30' alt='ardent-growth' />
          </div>
        </Card.Header>
        <Card.Body className='login-card-body'>
          <Form autoComplete='off'>
            {validationError && (
              <Alert variant='danger' className='invalid-alert'>
                <i className='fas fa-exclamation' />
                <span className='invalid-alert-message'>{validationError}</span>
              </Alert>
            )}
            <InputText
              label='USERNAME'
              state={username}
              setState={(e) => setUsername(e?.target?.value)}
              stylingClass='login-input-label'
            />
            <InputText
              type='password'
              label='PASSWORD'
              state={password}
              setState={(e) => setPassword(e?.target?.value)}
              stylingClass='login-input-label'
              onEnterPress={onLogin}
            />
          </Form>
          {!isLoginInProgress ? (
            <div className='signin-btn-container'>
              <button className='signin-button' onClick={onLogin}>
                Sign In
              </button>
            </div>
          ) : (
            <Loading whichLoader='login' />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
