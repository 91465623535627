import {
  SET_ALL_USERS,
  SET_IS_USER_AVATAR_HOVERED,
  SET_IS_USER_AVATAR_LOADING,
  SET_USER_AVATAR,
} from '../action-types/users';

const initialState = {
  allUsers: [],
  userAvatar: null,
  isUserAvatarLoading: false,
  isUserAvatarHovered: false,
};
export const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ALL_USERS: {
      return {
        ...state,
        allUsers: payload,
      };
    }
    case SET_USER_AVATAR: {
      return {
        ...state,
        userAvatar: payload,
      };
    }
    case SET_IS_USER_AVATAR_LOADING: {
      return {
        ...state,
        isUserAvatarLoading: payload,
      };
    }
    case SET_IS_USER_AVATAR_HOVERED: {
      return {
        ...state,
        isUserAvatarHovered: payload,
      };
    }
    default: {
      return state;
    }
  }
};
