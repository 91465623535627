import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { API_SOCKET_URL } from '../constants/environment-variables';

const useCustomSocket = (onChannel) => {
  const { lastJsonMessage, sendJsonMessage } = useWebSocket(API_SOCKET_URL, {
    onOpen: () => console.log(`WebSocket connection established.`),
    onClose: () => console.log('WebSocket connection CLOSED.'),
    shouldReconnect: (closeEvent) => true,
  });
  useEffect(() => {
    const channelMessage = (message) => {
      onChannel(message);
    };
    if (lastJsonMessage) {
      channelMessage(lastJsonMessage);
    }
  }, [onChannel, lastJsonMessage]);

  return {
    sendJsonMessage,
  };
};

export default useCustomSocket;
