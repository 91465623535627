import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompetitorDomainsList,
  setEditingDomin,
} from '../../../redux/actions/competitor-domain';

const EditDomain = ({ domain: { id, text } }) => {
  const [value, setValue] = useState(text);

  const isStartJobButtonClicked = useSelector(
    (state) => state?.jobFields?.isStartJobButtonClicked
  );
  const competitorDomainsList = useSelector(
    (state) => state?.competitorDomains?.competitorDomainsList
  );
  const dispatch = useDispatch();

  const saveEditedDomain = (domId) => {
    if (value) {
      const domIndex = competitorDomainsList
        ?.map(({ id }) => id)
        ?.indexOf(domId);
      const tempList = [...competitorDomainsList];
      delete tempList[domIndex];
      tempList[domIndex] = {
        id: domId,
        text: value,
      };
      dispatch(setCompetitorDomainsList(tempList));
      dispatch(setEditingDomin(null));
      setValue(null);
    }
  };
  return (
    <Form.Group className='edit-domain-input'>
      <Form.Control
        isInvalid={isStartJobButtonClicked && value}
        type='text'
        value={value}
        onChange={(e) => setValue(e?.target?.value)}
        onBlur={() => {
          if (value) {
            // saveEditedDomain(id);
          } else {
            dispatch(setEditingDomin(null));
          }
        }}
        autoFocus
        onKeyPress={(e) => {
          if (e?.key === 'Enter') {
            saveEditedDomain(id);
          }
        }}
      />
    </Form.Group>
  );
};

export default EditDomain;
