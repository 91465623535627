import React from 'react';
import { StyledDropZone } from 'react-drop-zone';
import uploadIcon from '../../../assets/img/upload-icon.svg';
import 'react-drop-zone/dist/styles.css';
import './styles.scss';

const DropFile = ({ fileName, setDocumentFile }) => (
  <div className='file-dropper'>
    <StyledDropZone
      accept='csv'
      dontRead
      onDrop={(file) => {
        if (file.type === 'text/csv') {
          setDocumentFile(file);
        }
      }}
    >
      <img src={uploadIcon} alt='upload' />
    </StyledDropZone>
    <div className='selected-file-name'>{fileName}</div>
  </div>
);

export default DropFile;
