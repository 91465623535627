import { Modal } from 'react-bootstrap';
import './LogoutModal.scss';
const LogoutModal = ({ show, handleClose, onLogout }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body className='logout-modal-body'>
        Are you sure you want to sign out?
      </Modal.Body>
      <Modal.Footer className='logout-modal-footer'>
        <button className='custom-button confirm-logout-btn' onClick={onLogout}>
          YES
        </button>
        <button className='custom-button close-btn' onClick={handleClose}>
          NO
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
