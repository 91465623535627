import React, { useState } from 'react';
import { getSupportedLocations } from '../../../utils/api';
import lodash from 'lodash';
import AsyncSelect from 'react-select/async';

const LocationSelect = ({ setLocation, stylingClass }) => {
  const getRelatedLocations = (locationQuery, updateLocationOptions) => {
    getSupportedLocations(locationQuery, 5).then(({ data }) => {
      updateLocationOptions(
        data.map((loc) => {
          var re = new RegExp(',', 'g');
          var formattedLoc = loc.location.replace(re, ', ');
          return { value: loc, label: formattedLoc };
        })
      );
    });
  };
  const [debouncedGetRelatedLocations] = useState(() =>
    lodash.debounce(getRelatedLocations, 2000)
  );

  return (
    <AsyncSelect
      className={stylingClass}
      cacheOptions
      onChange={(loc) => {
        setLocation(loc?.value);
      }}
      loadOptions={debouncedGetRelatedLocations}
    />
  );
};

export default LocationSelect;
