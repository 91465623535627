import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompetitorDomainsList,
  setEditingDomin,
  setIsAddingDomain,
} from '../../../redux/actions/competitor-domain';
import './CompetitorDomain.scss';
import EditDomain from './EditDomain';
const CompetitorDomain = ({ domain }) => {
  const { id, text } = domain;
  const editingDomain = useSelector(
    (state) => state?.competitorDomains?.editingDomain
  );
  const competitorDomainsList = useSelector(
    (state) => state?.competitorDomains?.competitorDomainsList
  );
  const dispatch = useDispatch();

  const removeDomain = (domId) => {
    if (competitorDomainsList?.length === 1) {
      dispatch(setIsAddingDomain(false));
    }
    dispatch(
      setCompetitorDomainsList(
        competitorDomainsList?.filter(({ id }) => id !== domId)
      )
    );
  };
  return (
    <div
      className={`tag-container ${
        editingDomain === id ? 'editing-domain' : ''
      }`}
    >
      <img
        src={require('../../../assets/img/hold-dots.svg').default}
        alt='hold-dots'
        className='hold-domain-icon'
      />

      {editingDomain !== id ? (
        <div onDoubleClick={() => dispatch(setEditingDomin(id))}>{text}</div>
      ) : (
        <EditDomain domain={domain} />
      )}

      {editingDomain !== id && (
        <img
          src={require('../../../assets/img/cross.svg').default}
          alt='cross'
          onClick={removeDomain.bind(this, id)}
          className='cross-domain-icon'
        />
      )}
    </div>
  );
};

export default CompetitorDomain;
