import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { isAddingDomainReducer } from './competitor-domain';
import { jobDetailsReducer } from './job-details';
import { jobFieldsReducer } from './job-fields';
import { jobsReducer } from './jobs';
import { sideBarReducer } from './sidebar';
import { usersReducer } from './users';
export const rootReucer = combineReducers({
  competitorDomains: isAddingDomainReducer,
  jobFields: jobFieldsReducer,
  jobDetails: jobDetailsReducer,
  auth: authReducer,
  jobs: jobsReducer,
  users: usersReducer,
  sideBar: sideBarReducer,
});
