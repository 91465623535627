export const fetcherKeys = [
  'target_domain',
  'search_engine',
  'country',
  'location',
  'language',
  'status',
  'type',
  'created_at',
  'updated_at',
  'ignore_special_characters',
  'reason_of_failure',
  'target_domain',
  'competitor_domains',
];
export const grouperKeys = [
  'grouping_method',
  'main_keyword_grouping_accuracy',
  'variant_keyword_grouping_accuracy',
  'status',
  'type',
  'created_at',
  'updated_at',
  'reason_of_failure',
  'target_domain',
  'competitor_domains',
  'organic_results_count',
  'number_of_clusters',
];
export const combinedKeys = [
  'grouping_method',
  'main_keyword_grouping_accuracy',
  'variant_keyword_grouping_accuracy',
  'search_engine',
  'country',
  'location',
  'language',
  'status',
  'type',
  'created_at',
  'updated_at',
  'reason_of_failure',
  'target_domain',
  'competitor_domains',
  'organic_results_count',
  'number_of_clusters',
];
export const hubKeys = [
  'status',
  'type',
  'created_at',
  'updated_at',
  'reason_of_failure',
  'number_of_clusters',
];
