import { getUserAvatar } from '../../utils/api';
import {
  SET_ALL_USERS,
  SET_IS_USER_AVATAR_HOVERED,
  SET_IS_USER_AVATAR_LOADING,
  SET_USER_AVATAR,
} from '../action-types/users';

export const setAllUsers = (users) => ({
  type: SET_ALL_USERS,
  payload: users,
});
export const setUserAvatar = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_USER_AVATAR_LOADING,
      payload: true,
    });

    const response = await getUserAvatar();
    dispatch({
      type: SET_USER_AVATAR,
      payload: response?.data?.url ?? '',
    });
    dispatch({
      type: SET_IS_USER_AVATAR_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: SET_USER_AVATAR,
      payload: null,
    });
    dispatch({
      type: SET_IS_USER_AVATAR_LOADING,
      payload: false,
    });
  }
};

export const setIsUserAvatarLoading = (loading) => ({
  type: SET_IS_USER_AVATAR_LOADING,
  payload: loading,
});
export const setIsUserAvatarHovered = (flag) => ({
  type: SET_IS_USER_AVATAR_HOVERED,
  payload: flag,
});
