import {
  SET_ACTIVE_JOBS,
  SET_ARCHIVED_JOBS,
  SET_DID_DATA_LOAD,
} from '../action-types/jobs';

export const setActiveJobs = (jobs) => ({
  type: SET_ACTIVE_JOBS,
  payload: jobs,
});
export const setArchivedJobs = (jobs) => ({
  type: SET_ARCHIVED_JOBS,
  payload: jobs,
});
export const setDidDataLoad = (flag) => ({
  type: SET_DID_DATA_LOAD,
  payload: flag,
});
