import React from 'react';
const PicLoader = () => {
  return (
    <div className='pic-loader-container'>
      <div className='pic-loader' />
    </div>
  );
};

export default PicLoader;
