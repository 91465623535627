import {
  SET_ACTIVE_JOBS,
  SET_ARCHIVED_JOBS,
  SET_DID_DATA_LOAD,
} from '../action-types/jobs';

const initialState = {
  activeJobs: [],
  archivedJobs: [],
  didDataLoad: false,
};
export const jobsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_JOBS: {
      return {
        ...state,
        activeJobs: payload,
      };
    }
    case SET_ARCHIVED_JOBS: {
      return {
        ...state,
        archivedJobs: payload,
      };
    }
    case SET_DID_DATA_LOAD: {
      return {
        ...state,
        didDataLoad: payload,
      };
    }
    default: {
      return state;
    }
  }
};
