import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAllUsers } from '../redux/actions/users';

export const useSortUsers = ({ users, setFilteredUsers }) => {
  const dispatch = useDispatch();
  const [lastUsedSortFilter, setLastUsedSortFilter] = useState('default');
  const [loginSortFilter, setLoginSortFilter] = useState('default');
  const sortByLastUsed = () => {
    setLoginSortFilter('default');
    if (lastUsedSortFilter === 'default') {
      const usersList = [...users];
      setLastUsedSortFilter('asc');
      usersList?.sort((a, b) => moment(a?.latestJob) - moment(b?.latestJob));
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
    } else if (lastUsedSortFilter === 'asc') {
      setLastUsedSortFilter('desc');
      const usersList = [...users];
      usersList?.sort((a, b) => moment(b?.latestJob) - moment(a?.latestJob));
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
    } else {
      const usersList = [...users];
      usersList?.sort((a, b) => moment(b?.latestJob) - moment(a?.latestJob));

      setLastUsedSortFilter('default');
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
    }
  };
  const sortByLastLogin = () => {
    setLastUsedSortFilter('default');
    if (loginSortFilter === 'default') {
      const usersList = [...users];
      setLoginSortFilter('asc');
      usersList?.sort((a, b) => moment(b?.last_login) - moment(a?.last_login));
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
    } else if (loginSortFilter === 'asc') {
      setLoginSortFilter('desc');
      const usersList = [...users];
      usersList?.sort((a, b) => moment(a?.last_login) - moment(b?.last_login));
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
    } else {
      const usersList = [...users];
      setLastUsedSortFilter('default');
      setLoginSortFilter('default');
      usersList?.sort((a, b) => moment(b?.latestJob) - moment(a?.latestJob));
      dispatch(setAllUsers(usersList));
      setFilteredUsers(usersList);
    }
  };

  return {
    lastUsedSortFilter,
    loginSortFilter,
    sortByLastUsed,
    sortByLastLogin,
  };
};
